.incident-management {
    margin-top: 40px;
    width: 100%;

    .bx--child-row-inner-container {
        max-height: unset !important;
    }

    .hide-hover .bx--cc--heatmap .cell-highlight {
        visibility: hidden !important;
    }

    .severity-wise {
        .severity-wise-incident-table {
            width: 80%;
            margin: 0 auto;
        }

        .cds--data-table {
            .cds--table-expand {
                overflow: hidden;
            }
        }
    }

    .breakfix-content {
        padding-bottom: 20px;

        .bx--pagination {
            flex-flow: wrap;
            background-color: white !important;
            border-bottom: 1px solid #e0e0e0;

            .bx--pagination__left {
                background-color: transparent;
            }

            .bx--pagination__right {
                background-color: transparent;
            }
        }
    }

    .react-card-flip {
        width: 100%;

        .no-border {
            border-right: unset !important;
        }

        .bx--modal {
            max-width: 100%;
        }
    }

    .switcher-container {
        display: flex;
        align-items: center;

        .bx--content-switcher {
            height: 2.2rem;
            width: fit-content;

            .bx--content-switcher-btn {
                width: fit-content;
            }
        }

        .download-button {
            margin-left: 20px;
        }

        .bx--dropdown__wrapper {

            .bx--dropdown {
                height: 2.25rem !important;

                .bx--list-box__menu-icon {
                    svg {
                        margin-bottom: 20px;
                        height: 1rem !important;
                        width: 1rem !important;
                    }
                }
            }
        }

        .data-range {
            padding-right: 20px !important;
        }
    }

    .progress-close-container {
        display: flex;
        align-items: center;
        column-gap: 16px;
        flex-wrap: wrap;
        justify-content: flex-end;
        row-gap: 8px;

        .bx--content-switcher {
            height: 2.2rem;
        }
    }

    .download-close-container {
        display: flex;
        row-gap: 8px;
        column-gap: 8px;

        .flip-button {
            cursor: pointer;
            height: 32px;
            width: 40px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
            text-align: center;
            color: #161616;
        }

        .flip-button:hover {
            background-color: #e5e5e5;
        }
    }

    .progress-bar-container {
        width: 120px;
        height: 10px;
        background: #ffffff;
        margin-bottom: 16px;

        .progress-bar-indicator {
            height: 100%;
            background-image: linear-gradient(to right, #0E6027, #FDDC69, #DA1E28);

            #indicator {
                width: 80%;
            }
        }

        .title,
        .range {
            color: #6F6F6F;
            font-size: 8px;
        }

        .range {
            display: flex;
            justify-content: space-between;
        }
    }

    .dashboard-detail-container .bx--data-table tbody tr {
        td:last-child {
            height: inherit;

            .percentage {
                width: 94px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .incident-management-table {
        min-height: 400px;
        display: flex;
        justify-content: space-between;
        padding-right: 5px;

        .table-container {
            width: 100%;
        }

        .bx--data-table-content {
            overflow-y: auto;
            max-height: 18.2rem;
        }

    }

    .link {
        cursor: pointer;
        color: #1192e8;
    }

    .dashboard-donut-chart {
        border: none;

        .chart-holder {
            height: 200px;
        }
    }

    .graph-title {
        flex-wrap: wrap;
    }

    .first-time-resolution,
    .severity-wise {
        .legend {
            margin-bottom: 20px;
        }
    }

    .first-time-resolution{
        background-color: white;
        padding: 20px;

        .table-container {
            .cds--table-expand {
                overflow: hidden;
            }
        }
    }

    .no-padding {
        .trend-graph {
            padding: unset !important;
        }
    }

    .graph-title .download-button {
        position: unset !important;

        button {
            align-items: center;
            display: flex;
            justify-content: center;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
            padding: 0 !important;
        }
    }
}