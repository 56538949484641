.bp--screen {
  .cds--cc--shape-node__body {
    position: absolute !important;
    text-align: center !important;
    top: calc(100% + 0.125rem) !important;
  }

  .cds--layer-two {
    background: white;
    padding: 8px;
    width: 100%;
    height: 40px;

    h5 {
      float: left;
    }

    button {
      float: right;
      min-height: 1rem;
    }

    h2 {
      font-weight: 800;
    }

    p {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .no--right__padding {
    padding-right: 0rem !important;
  }

  h5 {
    font-weight: 600;
  }

  .cds--tab-content {
    background-color: white;
    height: 700px;

    .topo-row {
      margin: 0;
    }
  }

  .tab-rows {
    margin: 0;
  }
}

div#campaignDay {
  margin-top: 15px;
}

.title-day {
  font-size: 2rem;
}

.Foreign-node {
  overflow: visible;
  background-color: #f4f4f4;

  .border-line {
    border-left: 2px solid #003a6d;
    height: 100%;
  }

  &.green {
    .border-line {
      border-left: 2px solid green;
    }
  }

  &.red {
    .border-line {
      border-left: 2px solid red;
    }
  }

  &.orange {
    .border-line {
      border-left: 2px solid orange;
    }
  }
}

.chart-check {
  margin-top: 8px;

  span.cds--radio-button__appearance {
    height: 14px;
    width: 14px;
    margin-right: 4px;
  }

  span {
    font-size: 0.7rem;
  }
}

// p.title {
//     font-size: 0.75rem !important;
// }

.bp--network__diagram {
  margin-left: 1rem;

  .dot--icon {
    margin-left: -3px;
    margin-bottom: -4px;
    color: #00539a;
  }

  .card-node {
    background: #f4f4f4;
    height: 50px;
    border: none;

    .cds--cc--card-node__title {
      text-transform: capitalize;
    }

    ul {
      li {
        span {
          width: 100%;
          text-align: justify;
          float: left;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .card--description {
    ul {
      li {
        span {
          width: 100%;
          text-align: justify;
          float: left;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .cds--tile__chevron {
    bottom: 1rem;
  }

  .inference--div {
    bottom: 10px;
    padding-right: 1rem;

    p {
      font-size: 10.5px;
      font-weight: 500;
    }
  }
}

.topology-graph-new {
  padding: 1rem;
  overflow-x: auto;

  svg {
    margin-top: 1em;
    margin-left: 1em;
  }

  .cds--cc--card-node__title {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: white;
    font-weight: 500;
  }

  .cds--cc--edge__inner {
    stroke: lightgrey;
  }

  .font-italic {
    font-style: italic;
  }

  .node__nocursor {
    .cds--cc--shape-node--button {
      cursor: auto;
      display: block;
      position: initial !important;

      .cds--cc--shape-node__icon {
        text-align: center;
        margin-top: 12px;
        display: block;
      }

      &.node_red {
        cursor: pointer;
        border: 3px solid #fa4d56;
        box-shadow: 0px 0px 5px #fa4d56;
      }
    }
  }

  .overflow-visible {
    overflow: visible;
  }

  .cds--cc--shape-node__body {
    text-align: center !important;
    position: initial !important;
    margin-top: 15px;
  }

  .html-spinner-red {
    position: relative;
    top: -64px;
  }

  .node--color__lightblue1 {
    background-color: #78a9ff !important;
  }

  .node--color__darkblue1 {
    background-color: #0f62fe !important;
  }

  .node--color__darkblue2 {
    background-color: #8a3ffc !important;
  }

  .node--color__darkblue3 {
    background-color: #0050e6 !important;
  }

  .node--color__pink {
    background-color: #f9d8d9 !important;
  }

  .node--color__lightblue2 {
    background-color: #b0eeef !important;
  }

  .node--color__darkblue4 {
    background-color: #7b33db !important;
  }

  .node--color__orange {
    background-color: #f1c21b !important;
  }

  .node--color__brown {
    background-color: #24a148 !important;
  }

  .node--color__darkgreen {
    background-color: #525252 !important;
  }

  .node--color__darkbrown {
    background-color: #750e13 !important;
  }

  .node--color__darkblue5 {
    background-color: #002d9c !important;
  }

  .node--color__lightbrown {
    background-color: #e65b5c !important;
  }

  .node--color__darkblue6 {
    background-color: #0043ce !important;
  }

  .node--color__lightblue3 {
    background-color: #b797f7 !important;
  }

  .node_dark_orange {
    border: 2px solid darkorange;
  }

  .node_orange {
    border: 2px solid orange;
  }

  .node_red {
    border: 2px solid red;
  }

  .cds--cc--card-node--button {
    background-color: #9f9f9f;
  }
}

.days {
  padding: 5px 10px;
  border-bottom: 2px solid #0e59e7;
  width: auto;
  display: inline-flex;
  margin-top: 10px;
  font-weight: 500;
  height: 25px;
}

.some-container {
  padding-top: 20px;
  background: white;
  padding-left: 20%;
  padding-bottom: 20px;
  height: 702px;

  .cds--progress-step {
    padding-bottom: 23.5px;
  }

  .cds--progress--vertical {
    .cds--progress-step {
      &:first-child {
        min-height: 1rem;
      }

      &:last-child {
        min-height: 1rem;
      }
    }

    .cds--progress-step-button {
      &:first-child {
        min-height: 1rem;
      }

      &:last-child {
        min-height: 1rem;
      }
    }

    .cds--progress-label {
      font-weight: 500;
      color: #0043ce;
    }

    .cds--progress-optional span {
      display: block;
      background-color: unset;
      font-size: 0.75rem;
    }
  }

  .tab-view {
    .cds--row {
      margin: 0;
    }
  }
}

.telco {
  position: relative;

  .viewAll {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.amexBox {
  .cds--cc--card-node {
    background-color: #e2e2e2;
  }
}

#my_dataviz {
  path {
    stroke: white;
    stroke-width: 0.25px;
    fill: #778899;
  }
}
