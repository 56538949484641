$w: 18.75em;
$inner-w: 16.2em;

.expanded-alert-table {
    .cds--expandable-row:not(:nth-child(1)) {
        td {
            padding-right: 0px !important;
        }
    }

    .cds--data-table {
        width: 100%;

        tr.cds--parent-row:nth-of-type(n) td:nth-child(2) {
            width: $w !important;
            min-width: $w !important;
            max-width: $w !important;
            word-break: break-word;
        }

    }

    .dashboard-detail-container .cds--data-table .cds--child-row-inner-container .table-container .cds--data-table tr td {
        min-width: 120px !important;
    }

    .nested-table {
        .expanded-table-title {
            padding: 15px 0px 15px 15px !important;
            border-bottom: 2px solid !important;
            font-family: "IBM Plex Sans";
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.16px;
        }

        .cds--data-table {
            th {
                &:not(:first-child) {
                    min-width: 120px !important;
                    max-width: 120px !important;
                }
            }

            .cds--child-row-inner-container {
                tr.cds--parent-row:nth-of-type(n) td:nth-child(2) {
                    width: $inner-w !important;
                    min-width: $inner-w !important;
                    max-width: $inner-w !important;
                    table-layout: unset;
                    word-break: break-word;
                }

                margin-left: 20px !important;

                .table-container {
                    margin-left: 0px !important;

                    .cds--data-table {
                        thead {
                            display: none;
                        }

                        tr.cds--parent-row:nth-of-type(n) td:nth-child(2) {
                            width: $inner-w !important;
                            min-width: $inner-w !important;
                            max-width: $inner-w !important;
                            table-layout: unset;
                            word-break: break-word;
                        }

                        tr {
                            min-width: 120px !important;

                            .cds--table-expand {
                                width: 0px !important;
                                min-width: 0px !important;
                            }

                            &:first-child {
                                td {
                                    border-top: unset;
                                }
                            }

                            td {
                                border-bottom: 0px !important;

                                &:nth-child(1):not(.cds--table-expand) {
                                    min-width: $inner-w;
                                    width: $inner-w;
                                    max-width: $inner-w;
                                    word-break: break-word;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}