.adani-nerve {
    .pd-l{
        padding-left: 0 !important;
    }
    .pd-r{
        padding-right: 15px !important;
    }
    .inference {
       // background-color: #fff;
       padding: 12px;
       margin-bottom: 22px;
       background: white;
       margin-top: 15px;
        h5 {
            margin-bottom: 5px;
        }
        li {
            font-size: 0.85rem;
            text-indent: 0px;
            padding-left: 10px;
            list-style-type: decimal;
            line-height: 1rem;
            margin: 0 15px;
            padding-bottom: 10px;        
        }
        svg {
            vertical-align: sub;
         }
         &.infy {
            //padding-left: 0px !important;
          //  padding: 10px !important;
            li{
                list-style-type: none !important;
            }
         }
    }

    .inference1 {
        // background-color: #fff;
        padding: 12px;
        margin-bottom: 22px;
        background: white;
        margin-top: 15px;
        width: 49%;
        height: 138px;
         h5 {
             margin-bottom: 5px;
         }
         li {
             font-size: 0.85rem;
            //  text-indent: 0px;
            //  padding-left: 10px;
            //  list-style-type: decimal;
             line-height: 1rem;
             margin: 0 15px;
             padding-bottom: 10px;        
         }

         svg {
            vertical-align: sub;
         }
     }

    .cds--cc--edge--dash-sm{
        stroke-dasharray: 4 6;
    }

    .topology-graph .cds--cc--edge__inner
    {
        stroke: #8d8d8d
    }

    .service-chart{
        background-color: #fff;
        margin-top: 15px;
        margin-right: 15px;
        max-inline-size: 48%;
        .cds--tile.chart-container{
            width: 80%;
            float: left;
            .title{
                font-size: 0.8rem !important;
            }
        }
        ul {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 20px 0;
            // align-items: center;
            // height: 100%;
            li {
                width: 80%;
                text-align: center;
                padding: 10px;
                margin-right: 10px;
                margin-top: 15px;
                cursor: pointer;
                color: #fff;
            }
        }
    }

    .rgt-corner {
        width: 9%;
        display: flex;
        position: fixed;
        li {
            cursor: pointer;
            text-align: center;
            font-weight: bold;
            // background: #e0e0e0;
            padding: 8px;
            float: left;
            margin-right: 2px;
            &.active{
                height: 48px;
                background-color: #fff;
                border-top: 2px solid #0f62fe;
            }
            &:first-child {
                left: 0;
                position: absolute;
            }
            &:last-child {
                right: 0px;
                position: absolute;
            }
        }
    }

    .rgt-col{
        border-left: 1px solid #ccc;
    }

    .pd-lft{
        padding-inline-start: 0;
    }

    .pd-rgt {
        padding-inline-end: 0;
    }

    .search {
        .pd-rgt {
            padding-inline-end: 25px;
        }
    }

    .date-sec {
        display: inline-flex;
        width: 85%;
        justify-content: end;
    }

    .first-row {
        display: flex;
        justify-content: space-between;

        .cds--tile {
            margin-top: 0 !important;
            text-align: center;
            height: 100px;
            span {
                font-size: 1.5rem;
            }
        }

    }

    .table-app {
        cursor: pointer;

    }

    .selectedAp {
        color: #0f62fe;
        svg {
            vertical-align: sub;
        }
    }

    .tiles-details {
        li {
            font-size: 0.85rem;
            text-indent: 0px;
            padding-left: 10px;
            list-style-type: decimal;
            line-height: 1rem;
            margin: 0 40px;
            padding-bottom: 10px;        
        }
    }

    .head-sec {
        display: flex;
        margin-bottom: 15px;
        .lft-tag {
            display: inline-flex;
            width: auto;
        }
        ul.icon-actions {
            display: flex;
            li {
                svg {
                    cursor: pointer;
                }
            }
        }
    }

    .cds--list-box__menu-item__option {
        text-transform: capitalize;
    }
}

.topology-view {
    .cds--modal-container--lg {
        inline-size: 85%;
    }

    .cds--cc--card-node__title{
        font-size: 1.2rem;
    }
}

.cds--tab-content:focus {
    outline: none !important;
    outline-offset: 0px !important;
}

.tile-combo {
    display: flex;
}

.clickable-tile-portfolio-att {
    float: left;
}

.nerve-tiles {
    .cds--tile--clickable {
        float: left;
        width: 23.8%;
        margin-right: 15px;
        min-height: 150px;
        height: 180px;
        border-radius: 5px;
        box-shadow: 1px 1px #ccc;
        h5 {
            color: #4589ff;
            float: left;
            width: 70%;
        }
        .cds--tag {
            text-transform: capitalize;
            float: right;
        }
        .cds--cc--layout-row{
            display: none;
        }
        .cds--tile.chart-container{
            margin-top: 0;
        }
        .spacer{
            display: none;
        }
        ul {
            display: flex;
            width: 100%;
            li {
                font-size: 1.8rem;
                font-weight: 600;
                float: left;
                width: 40%;
                &:first-child {
                    margin-top: 80px;
                }
                span {
                    font-weight: 400;
                    font-size: 1rem;
                }
                &.comments {
                    font-size: 1rem;
                    padding-top: 5px;
                    width: 60%;
                    text-align: center;
                }
            }
        }
    }
}