.alert-summary-container {
    margin-top: 15px;
    .manage-automation-page-alerts {
        margin-left: -15px;
    }

    .alert-summary-table{
        .title-filter-container{
            margin: 10px 0;
        }
    }

    .manage-automation-page__subheading {
        color: #161616;
        font-family: IBM Plex Sans;
        font-size: 24px;
        font-weight: 600;
        height: 26px;
        letter-spacing: 0;
        line-height: 26px;
        /* margin-left: -15px; */
        margin-top: 10px;
        width: 250px;
    }

    .tabs-container {
        position: relative
    }

    .cds--tabs .cds--tab--overflow-nav-button .cds--tabs__nav-item {
        background: #E0E0E0 !important;
        font-family: "IBM Plex Sans light";
        width: 100%;
        min-width: 160px;
        font-size: 14px;
        font-weight: 600;
        color: black;
        letter-spacing: 0.45px;
    }

    .cds--tabs {
        position: absolute;
        width: 40%;
        z-index: 100;
        //border-right: 1px solid #E0E0E0;

        .cds--tab--list {
            display: flex;
            gap: 20px;
            margin-left: 10px;
            padding-top: 13px;

            .cds--tabs__nav-item {
                box-shadow: none !important;
                align-items: center;
                border-radius: 40px;
                display: flex;
                height: 25px;
                justify-content: center;
                min-width: 80px !important;
                background: #d0e2ff !important;
                margin-left: 20px;
            }

            .cds--tabs__nav-item-label-wrapper .cds--tabs__nav-item-label {
                color: #0043ce;
                align-items: center;
                display: flex;
                height: 25px;
                justify-content: center;
                min-width: 80px !important;
                padding-bottom: 3px;
                font-size: 14px;
            }
        }

        .cds--tabs__nav-item--selected {
            border-block-end: none !important;
        }
    }

    .filter-tag-container {
        .filters {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .cds--tag--blue {
                span {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .pagination-container {
        display: flex;

        .fastforward {
            border-top: 1px solid #E0E0E0;
            border-left: 1px solid #E0E0E0;
            background: white;

            svg {
                height: 10px !important;
                width: 10px !important;
            }

            .bx--assistive-text {
                transform: translate(-50%) translateX(-35%);
            }

            .cds--btn.cds--btn--ghost {
                padding: 15px !important;
            }
        }
    }

    .error-msg {
        margin: 10px 0;
        .sub-desc {
            display: flex;
            margin-bottom: 5px;
            span {
                margin-right: 5px;
            }
        }
        .some-class {
            margin: 0;
        }
    }
}