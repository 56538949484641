// Variables
$tab-height: 48px;
$primary-color: #0f62fe;
$hover-color: #f4f4f4;
$selected-bg: #EDF5FF;
$border-color: #e0e0e0;
$text-color: #525252;

.tabs-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.tabs-content {
  flex: 1 1 auto;
  overflow: auto;
  padding-bottom: $tab-height;
}

.custom-tab-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid $border-color;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
}

// Rest of the styles remain the same but using variables
.custom-tab-list {
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  height: $tab-height;
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-tab {
  flex: 1;
  height: 100%;
  border: none;
  background: none;
  padding: 0 8px;
  font-size: 14px;
  color: $text-color;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  font-family: inherit;

  &:hover {
    background: $hover-color;
  }

  &.custom-tab-selected {
    color: $primary-color;
    font-weight: 600;
    background: $selected-bg;
    border-bottom: 2px solid $primary-color;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $primary-color;
    }

    &:hover {
      background: $selected-bg;
    }
  }
}

/* Hide original Carbon tabs */
.cds--tabs__nav {
  display: none !important;
}

.cds--tab-content {
  padding: 0 !important;
}