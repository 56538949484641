@import "../../../Css/variable";

.wizard-hm {
    .cds--tile--clickable {
        box-shadow: 0 1px 2px #12161929;
        border-radius: 5px;
        padding-bottom: 25px;
        min-height: 190px;
        height: 100%;
        padding: 10px;

        .report-title {
            height: 35px;

            svg {
                vertical-align: middle;
            }
        }

        p.desc {
            font-size: 0.9rem;
        }

        .notify-tag {
            margin-left: 5px;
        }

        .arrow-icon {
            float: right;
            fill: $dark-grey;
        }

        .rgt-aw {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }

    }

    .cds--skeleton__placeholder {
        margin-top: 15px;
        inline-size: 21.5rem;
    }
}

.insights-hb {
    height: 580px;
    padding: 0.5rem !important;

    .basic-info {
        margin-top: 10px;
        display: flex;

        li {
            box-shadow: 0 1px 4px #12161929;
            border-radius: 5px;
            float: left;
            padding: 8px;
            margin-right: 5px;
            height: 60px;
            position: relative;

            &:last-child {
                margin-right: 0;
            }
        }

        h5 {
            font-size: .75rem;
            line-height: 0.85rem;
            height: 30px;
        }

        span {
            font-size: 1rem;
            font-weight: 600;
            text-align: center;
            width: 100%;
            display: inline-block;
        }
    }

    .chart-box {
        display: flex;
        flex-wrap: wrap;

        .chart-container {
            padding: 5px;
            width: 50%;
            float: left;
            border-radius: 5px;
            margin-right: 5px;
            position: relative;
            flex-grow: 1;
            flex-basis: fit-content;
            height: 182px;

            &:last-child {
                margin-right: 0px !important;
            }

            .cds--cc--chart-wrapper {
                .header {
                    // position: absolute;
                    // bottom: 0;
                }

                .spacer {
                    height: 0 !important;
                }

                text {
                    font-size: 10px;
                }
            }

            .title {
                font-size: 0.75rem !important;
                width: 100% !important;
                text-align: center;
            }

            .legend-item {
                margin: 2px;

                .checkbox {
                    width: 10px;
                    height: 10px;
                }

                p {
                    font-size: 10px;
                }
            }
        }
    }

    .cds--link--disabled {
        opacity: 0.5;
    }
}

.landing-main {
    background-position: 0 0;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.dash-svg {
    display: flex;
    height: 86vh;
    width: 100%;

    .text-head {
        cursor: pointer;

        .prefix__cls-672 {
            fill: #0f62fe !important;

            &:hover {
                fill: #393939 !important;
            }
        }

        &.false {
            cursor: not-allowed;
            opacity: 0.3;

            &:hover {
                fill: #000000 !important;
            }
        }
    }

    .text-heading {
        cursor: pointer;
        fill: "#262626";
        font-size: 14px;
        font-size: 1.5rem;

        &.false {
            cursor: not-allowed;
            opacity: 0.3;

            &:hover {
                fill: #000000 !important;
            }
        }

        &:hover {
            fill: #0f62fe !important;
        }

        .itGovern {
            translate: 190px 770px;
        }

        .itValue {
            translate: 542px 525px;
        }

        .sustain {
            translate: 850px 305px;
        }

        .lifecycle {
            translate: 1350px 408px;
        }

        .security {
            translate: 1130px 570px;
        }

        .operation {
            translate: 850px 770px;
        }

        .Service {
            translate: 560px 980px;
        }

        .Aicore {
            translate: 1190px 980px;
        }

        .instrument {
            transform: rotate(-37deg);
            translate: 1580px 690px;
        }

        .solution-stk {
            transform: rotate(-37deg);
            translate: 1270px 420px;
        }

        text {
            font-weight: 600;
            transform: rotate(-35deg);
        }
    }

    .text-heading1 {
        fill: "#262626";
        font-size: 14px;

        .Aicore {
            translate: 1190px 980px;
        }

        .solution-stk {
            transform: rotate(-37deg);
            translate: 1270px 420px;
        }

        text {
            font-weight: 600;
            transform: rotate(-35deg);
            font-size: 1.5rem;
        }
    }

    .box-highlight {
        &:hover {
            cursor: pointer;
            filter: drop-shadow(13px 13px 25px #8a3ffc);
        }
    }

    .overall-box-highlight {
        cursor: pointer;
        filter: drop-shadow(13px 13px 25px #8a3ffc);
    }



    .prefix__cls-1341,
    .prefix__cls-1345 {
        stroke: #78a9ff;
    }

    .dash-arrow-rgt {
        animation: link-anim-rt 8s infinite linear reverse;
        stroke: #78a9ff;
    }

    .dash-arrow-up {
        animation: link-anim-up 8s infinite linear reverse;
        stroke: #78a9ff;
    }

    .dash-arrow-lft {
        animation: link-anim-lft 8s infinite linear alternate;
        stroke: #78a9ff;
    }

    @keyframes link-anim-lft {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 400;
        }
    }

    @keyframes link-anim-up {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 400;
        }
    }


    @keyframes link-anim-rt {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 400;
        }
    }

    .dash-arrow {
        animation: link-anim-bt 8s infinite linear;
        stroke: #78a9ff;
    }

    @keyframes link-anim-bt {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 400;
        }
    }

}

.left-icons{
    position: fixed;
    margin-left: 0px;
    bottom: 20px;
    display: flex;
    align-items: end;
    .copy-rgt {
        float: right;
        li {
            font-size: 0.8rem;
            margin-top: 0px !important;
            margin-bottom: 0 !important;
        }
    }
    .priva {
        color: #0f62fe;
        cursor: pointer;
        margin-left: 20px;
        font-size: 0.85rem;
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
    
    .privacy-pop{
        h4{
            font-weight: 600;
            margin-bottom: 10px;
        }
        ul {
            li {
                margin-bottom: 15px;
                p {
                    color: #0f62fe;
                    font-weight: 600;
                }
            }
        }
    }
}

.rgt-icons {
    position: relative;

    ul {

        li {
            background: #393939;
            margin-bottom: 5px;
            box-shadow: 1px 1px #161616;
            text-decoration: none;

            &:hover {
                background-color: #393939;
                cursor: pointer;
            }

            .ms-team {
                height: 40px;
            }

            img {
                width: 35px;
                padding: 10px;
            }
        }
    }
}

.icon-li {
    position: fixed;

    right: -52px;
    width: 120px;
    transform: translateX(100%) translateX(-85px);

    // transform: translateX(60%) translateX(10px);
    &:hover {
        // animation-delay: 3s;
        animation-duration: 3s;
        animation-name: ChatButton_animate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
        transform: translateX(50px);
    }

    span {
        padding-top: 10px;
        color: white;
        text-decoration: none;
    }

    &.teams {
        bottom: 260px;
    }

    &.ops {
        bottom: 220px;
    }

    &.bot {
        bottom: 175px;

        img {
            width: 40px;
        }
    }

    &.query {
        bottom: 128px;

        img {
            width: 40px;
        }
    }

    &.voice {
        bottom: 82px;

        img {
            width: 40px;
        }
    }

    &.noc {
        bottom: 35px;
    }
}

@keyframes ChatButton_animate {
    0% {
        transform: translateX(20%) translateX(-20px);
    }

    20% {
        transform: translateX(-40px);
    }

    80% {
        transform: translateX(-40px);
    }

    100% {
        transform: translateX(100%) translateX(-100px);
    }
}

.aiops {
    position: fixed;
    top: 60px;

    img {
        width: 140px;
    }
}

.insights {
    &:hover {
        cursor: pointer;
        filter: drop-shadow(-30px -11px 15px #4589ff);
    }
}

.hide-text {
    display: none;
}

.show-text {
    display: block;
}

text.heading {
    font-size: 1.5rem;
    fill: #740937;
}

.overlay {
    position: fixed;
    /* Sit on top of the page content */
    //display: none;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    // cursor: pointer;
    /* Add a pointer on hover */
}

.carousal {
    margin-top: 10px;
    text-align: center;
    float: left;
    width: 70%;

    .dot {
        cursor: pointer;
        height: 8px;
        width: 8px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
    }

    .active,
    .dot:hover {
        background-color: #4589ff;
    }
}

.prev {
    width: 45%;
    display: inline-flex;
    float: left;
}

.carousal {
    margin-top: 10px;
    text-align: center;
    float: left;
    width: 60%;
}

.tour-btn {
    float: right;

}

.done-btn {
    padding: 14px 10px !important;
    margin-right: 20px !important;
}

.guid-footer {
    position: absolute;
    bottom: 32px;
    right: 100px;
    z-index: 99;
    width: 140px;
}

.skip-btn {
    position: absolute;
    right: 100px;
    top: 15px;
    padding: 10px 20px;
    min-height: 10px;
    cursor: pointer;
}


.sec-layer {
    position: relative;
}

.btn-g {
    cursor: pointer;
}

