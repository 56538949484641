@import "../../../../Css/variable";

.section-divider {
  margin: 15px 1rem 0 0;
  border: 1px solid #b6b9bf;
}

.green-theme {

  // Buttons and text
  .susops-btn,
  .susops-color {
    color: $susops_primary;
  }

  .susops-btn:hover {
    color: $susops_primary;
  }

  .susops-btn-bg {
    background-color: $susops_bg;
  }

  .susops-btn-bg:hover,
  .cds--btn--primary {
    background-color: #00723b;
  }

  .susops-btn-bg:active {
    background-color: #00522a;
  }

  .susops-back-btn {
    background-color: #4c4c4c;
  }

  .susops-back-btn:hover {
    background-color: #434343;
  }

  .susops-back-btn:active {
    background-color: #3b3b3b;
  }

  // Tabs
  .cds--tabs__nav-item--selected {
    color: $susops_primary !important;
    border-bottom: 2px solid $susops_borders !important;
  }

  // Data table
  .carbon--data--table, .cds--data-table {
    thead {
      th {
        background-color: $susops_bg !important;

        button {
          background-color: $susops_bg;
          color: $susops_bg_text;
        }
        
        .cds--table-header-label{
          color: $susops_bg_text;
        }
      }
    }

    tr:not(.cds--parent-row):nth-child(2n) {
      td {
        background-color: initial;
      }
    }

    h4 {
      margin-bottom: 0.5rem;
      color: $susops_primary;
    }
  }

  .recommedations--tabel {
    .carbon--data--table {
      tr:not(.cds--parent-row):nth-child(1) {
        td {
          color: #a0a0a0 !important;
        }
      }
    }
  }

  // Structured Lists
  .susops-tables {

    .cds--structured-list-th,
    .cds--structured-list-td {
      padding: 0.75rem !important;
    }

    .cds--structured-list-row:nth-child(2n) {
      background-color: initial;
    }

    .cds--structured-list-row--header-row {
      background-color: $susops_bg;

      .cds--structured-list-th {
        color: $susops_bg_text;
      }
    }
  }

  // Icons
  .info-icon {
    fill: $susops_primary !important;
  }

  // charts title
  .graphs-row {
    .cds--cc--title {
      p {
        color: $susops_primary;
      }
    }
  }

  // Progress Bar
  .cds--progress {
    svg {
      fill: $susops_primary;
    }

    .cds--progress-step--current,
    .cds--progress-step--complete {
      .cds--progress-line {
        background-color: $susops_bg;
      }
    }
  }

  // Breadcrumns
  .cds--breadcrumb-item {
    a {
      color: $susops_primary !important;
    }
  }

  .form-field-max-width {
    max-width: 500px;
  }

  .cds--radio-button__appearance:before {
    background-color: $susops_primary !important;
  }
}

// Inference
.inference-heading {
  text-align: center;
  background-color: $susops_bg;
  color: #fff;
  padding: 0.5rem;

  span {
    font-weight: 450;
  }
}

.inference-tile {
  padding: 0 !important;
  height: 88%;

  .inference {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 2.5rem;
    gap: 1rem;

    svg {
      fill: $susops_bg;
      size: 30px;
    }

    .inference-description {
      font-weight: 400;
      color: $susops_primary;
    }

    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 42%;
      cursor: pointer;
      fill: $susops_primary;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.2);
      }
    }

    .left-arrow {
      left: 5px;
    }

    .right-arrow {
      right: 5px;
    }
  }

  .ml-inference {
    @extend .inference;

    .inference {
      justify-content: center;
    }

    .left-arrow,
    .right-arrow {
      top: 52%;
    }
  }
}

.info-icon {
  cursor: pointer;
  margin-top: 0.4rem;
}

.table-container {
  .cds--chart-holder .cds--cc--toolbar {
    display: none;
  }
}

.table-in-chart {
  .carbon--data--table {
    margin-top: 0px;
    width: 100%;
  }

  .table-container-in-chart {
    .cds--chart-holder .cds--cc--toolbar {
      display: none;
    }

    .cds--data-table-header {
      padding: 0px;

      .cds--data-table-header__title {
        font-size: 1rem;
        color: var(--cds-text-primary, #161616);
        font-family: IBM Plex Sans, system-ui, -apple-system, BlinkMacSystemFont, \.SFNSText-Regular, sans-serif;
        font-size: 16px;
        font-weight: 600;
        overflow: hidden;
        padding-right: 15px;
        padding-bottom: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}


.emission--tile {
  margin-top: 0;

  h6 {
    font-weight: bold;
  }

  p {
    font-size: 0.75rem;

    span {
      font-weight: bold;
    }
  }

  .down {
    svg {
      fill: #14943a;
    }

    span {
      color: #14943a;
    }
  }

  .up {
    svg {
      fill: #da1e28;
    }

    span {
      color: #da1e28;
    }
  }

  .total-value {
    font-weight: 400;
    font-size: 20px;
    display: inline;
  }
}

.heading-parent {
  position: relative;

  .manual-legends {
    margin-top: 0;

    li::before {
      content: "";
    }

    .legend-name {
      font-size: 16px;
      font-weight: 400;
    }

    span {
      font-size: 14px;
    }
  }

  .potential--reduction {
    z-index: 1;
    position: absolute;
    top: 6rem;
    right: 2rem;

    h6 {
      font-weight: bold;
    }

    .total-value {
      font-weight: 400;
      font-size: 20px;
      display: inline;
      color: #14943a;
    }
  }

  .tickets-heading {
    color: $susops_primary;
    font-size: 0.75rem;
    font-weight: 600;
    z-index: 1;
    position: absolute;
    top: 3.1rem;
    left: 2rem;
  }

  .app-tickets-heading {
    @extend .tickets-heading;
    left: 1rem;
  }

  .per-incident-heading {
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    right: 1rem;

    .per-incident-icon {
      fill: $susops_primary !important;
    }
  }

  .manual-heading {
    position: absolute;
    z-index: 1;
    top: 1rem;
    right: 2rem;
  }

  .manual-heading-info {
    position: absolute !important;
    z-index: 1;
    top: 1.5rem;
    right: 1rem;

    .info-icon {
      fill: #161616;
    }

    .assumption {
      list-style: auto !important;
      padding-left: 1rem;

      li {
        font-style: 12px;
      }
    }
  }
}

.chart--heading {
  color: #161616;
  font-size: 0.75rem;
  font-weight: 600;
  overflow: hidden;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.value-highlight {
  color: $susops_primary;
  font-size: 14px;
  font-weight: 450;
  line-height: 1.5;
}

.keyValue {
  display: flex;
  gap: 3px;

  h5,
  h4 {
    font-size: 14px;
  }

  h5 {
    font-weight: 350;
  }

  h4 {
    @extend .value-highlight;

    strong {
      @extend .value-highlight;
      font-weight: bold;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
  }

  .cds--dropdown {
    height: 1.45rem;
    border-bottom: 1px solid $susops_borders;
    background-color: #f4f4f4;
  }

  .cds--list-box__label {
    color: $susops_primary;
    font-weight: 450;
  }
}

.extra-padding {
  .chart-container {
    padding-bottom: 2.5rem;
  }
}

.susops {
  .cds--tile.tiles.infer {
    padding: 23px;
    background-color: $susops_bg;
    color: #ffffff;
  }

  .country {
    display: flex;
    clear: both;
    width: 100%;
    margin-top: 10px;

    h4.overview-tile {
      width: 50%;
      font-size: 1rem;
      float: left;
    }
  }

  .global-view {
    position: absolute;
    right: 30px;
    top: -50px;
  }
}

.select-app {
  input {
    border-bottom: 1px solid $susops_borders;

    &::placeholder {
      color: #161616;
    }
  }
}

.img-aiops {
  width: 100%;
}

.bg-color {
  td {
    background-color: #42be65 !important;
  }
}

.infer {
  li {
    list-style: inside;
    font-size: 0.8rem;
    padding: 0 10px;
  }
}

.resolution-info-icon {
  position: absolute;
  top: 450px;
  left: 238px;
}

.resolution-popUp {
  background-color: #fff;
  box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.3);
  height: 30px;
  position: absolute;
  width: 25rem;
  top: 415px;
  left: 210px;
  text-align: center;
  font-weight: 300;
  padding-top: 5px;
}

.add-icon-button.cds--btn.cds--btn--primary {
  background-color: grey !important;

  min-height: 0.5px !important;
}

.accordian--wraper {
  margin-top: 2rem;

  .cds--accordion__content {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.button-inline-text {
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 3px;
  padding-left: 4px;
}

.team-details {
  input[type="number"] {
    background-color: #fff !important;
  }

  input {
    background-color: #fff !important;
  }
}

.it-ops {
  .cds--content-switcher {
    height: auto;
    flex-direction: column;
    width: 100% !important;

    .cds--content-switcher-btn {
      border: 0 !important;
      padding: 15px;

      &:first-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      &:last-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  .cds--content-switcher--selected::after {
    background-color: $susops_bg;
    color: $susops_bg_text;
  }
}

.invisible-heading {
  .cds--cc--title {
    .title {
      visibility: hidden;
    }
  }
}

.country {
  text-transform: capitalize;
}

// Communuication
.list-cell-rows {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hortizontal-scroll {
  width: 100%;
  overflow-x: auto;
}

.label-font-size {
  .cds--label {
    font-size: 0.69rem;
  }
}

.recommendations-table {
  .cds--tabs .cds--tabs__nav-link {
    max-width: 15rem !important;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 1rem 1rem 2px;
}

.application-types {
  .application-tiles {
    padding: 0.6rem;
    margin-top: 0 !important;
    border-radius: 12px;
    box-shadow: 0px 10px 15px -3px rgba(74, 76, 78, 0.3);

    p {
      font-size: 0.875rem;
      text-align: center;
      font-weight: 400;

      &:nth-child(1) {
        margin-bottom: 5px;
      }
    }
  }

  .tile-1 {
    color: #fff;
    background-color: #4a4c4e;
  }

  .tile-3 {
    color: black;
    background-color: #deab78;
  }

  .tile-2 {
    background-color: #fddc69;
    color: black;
  }
}

.f-12 {
  font-size: 12px;
}

.download-button {
  position: absolute !important;
  top: 0rem;
  right: 2rem;
}

.itsm--forms {

  input,
  .cds--list-box__field {
    background-color: #f4f4f4 !important;
  }
}

.tile-style-heading {
  text-align: center;
  color: #030303;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 800;
}

.fonts-tiles {
  display: flex;
  gap: 3px;

  h3 {
    font-size: 8px;
    font-weight: 100;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
  }
}

.arrow-style {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

.hide--tooltip {
  .manual-heading-info {
    display: none;
  }
}