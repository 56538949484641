.landing-pg {
  height: 100%;
  background-size: cover;
  width: 100%;
  background-position: initial;

  h5.proj-title {
   // background-color: #d6d6d6;
    font-weight: 500;
    padding: 10px;
    position: absolute;
    img {
      width: 240px;
    }
  }

  .login-svg {
    display: flex;
    height: 99vh;
    width: 100%;

    .guidedTour-page1 {
      background-color: #fff;
      box-shadow: 5px 5px 8px 6px rgba(0, 0, 0, 0.3);
      height: 800px;
      position: absolute;
      width: 80rem;

      .popup-btn {
        position: absolute;
        left: 1100px;
        bottom: 50px;
      }
    }

    .guidedTour-page2 {
      background-color: #fff;
      box-shadow: 5px 5px 8px 6px rgba(0, 0, 0, 0.3);
      height: 1000px;
      position: absolute;
      width: 50rem;

      .popup-btn1 {
        position: absolute;
        left: 10px;
        bottom: 50px;
      }

      .popup-btn2 {
        position: absolute;
        left: 650px;
        bottom: 50px;
      }
    }
  }

  .text-sec {
    font-size: 11px !important;
    padding: 10px;
    position: absolute;
    right: 0;
    width: 104px;
  }

  .text-sec.sign {
    top: 20px;
    display: block;
  }

  .text-sec.video {
    top: 70px !important;
  }

  .text-sec.learn {
    bottom: 40px !important;
  }

  .video-tour-btn {
    position: absolute;
    left: 65rem;
    top: 5px;
  }

  .learn-more-btn {
    position: absolute;
    left: 65rem;
    top: 5rem;
  }

  .text-sec {
    font-size: 1rem;
  }

  .landing-main-modal {
    .cds--modal-header__heading {
      padding: 0;
    }

    .cds--modal-header {
      padding-right: 1rem !important;
    }

    .cds--modal-container {
      min-height: 20rem;
    }

    .cds--modal-close {
      display: none;
    }

    .cds--modal-content {
      margin-bottom: 0rem !important;
      padding-bottom: 0;
    }

    .skip-btn {
      float: right;
      text-decoration: none;
      color: grey;
      font-size: 0.7rem;
    }

    .tour-btn {
      float: right;
      text-decoration: none;
      font-size: 0.65rem;
    }

    .tour-icon {
      height: auto;
      width: 100%;
    }

    .carousal {
      margin-top: 10px;
      text-align: center;
      float: left;
      width: 65%;

      .dot {
        cursor: pointer;
        height: 8px;
        width: 8px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      }

      .active,
      .dot:hover {
        background-color: #4589ff;
      }
    }

    span {
      font-size: 0.75rem;
      line-height: 1rem;
      display: inline-block;
    }

    .prev {
      width: 15%;
      display: inline-flex;
      float: left;

      button {
        font-size: 0.65rem;
      }
    }

    .guid-footer {
      margin-top: 20px;
    }

    p {
      color: #0050E6;
      font-weight: 500;
    }

    .content-panel {
      padding: 0 20px;
    }
  }

  .landing-video-modal {
    .cds--modal-close:hover {
      background-color: transparent !important;
    }
  }


  .login-svg {
    display: flex;
    width: 100%;

    .text-heading {
      fill: "#262626";
      font-size: 14px;

      .itGovern {
        translate: 148px 534px;
      }

      .itValue {
        translate: 375px 368px;
      }

      .sustain {
        translate: 583px 220px;
      }

      .lifecycle {
        translate: 1020px 254px;
      }

      .security {
        translate: 856px 370px;
      }

      .operation {
        translate: 660px 510px;
      }

      .Service {
        translate: 470px 656px;
      }

      .Aicore {
        translate: 965px 720px;
      }

      .instrument {
        transform: rotate(-37deg);
        translate: 1230px 660px;
      }

      .solution-stk{
        transform: rotate(-37deg);
        translate: 1270px 420px;
    }

      text {
        font-weight: 600;
        transform: rotate(-35deg);
      }
    }

    .prefix__cls-1341,
    .prefix__cls-1345 {
      stroke: #78a9ff;
    }

    .dash-arrow,
    .dash-arrow-rgt,
    .dash-arrow-up,
    .dash-arrow-lft {
      stroke: #78a9ff;
    }
  }

  .landing-main {
    background-position: 0 0;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}