.keyvalue {
	float: left;
	input {
		width: 90% !important;
	}
}

.clear-box {
	clear: both;
}

addkey-btn.cds--btn {
	clear: both;
	display: block;
}

.errmsg-form {
	color: red;
}
