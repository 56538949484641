.cicd {

  .pro-heading{
    display: flex;
    align-items: center;
    height: 8vh;
    padding-top: 10px;
  }

  .cds--snippet--has-right-overflow {
    background: #f4f4f4;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .text-description span {
    font-size: 0.95rem;
  }

  .pipeline-name {
    display: flex;
    gap: 0.2rem;

    ul li {
      float: left;
      margin-right: 5px;

      .svg {
        display: flex;
        justify-content: center;
        .green {
          fill: #24a148 !important;
        }
        .red{
          fill: #fa4d56 !important;
        }
      }

      span {
        font-size: 0.6rem;
      }
    }

    .orange {
      fill: orange;
      animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    }

    @keyframes blinker {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }

  &.overview {
    .cds--tile {
      margin-bottom: 20px;
    }

    .app-name.cds--structured-list-td {
      text-decoration: underline;
      color: #1414e3;
      cursor: pointer;
    }

    .flex-display {
      float: left;
    }
  }

  .carbon--data--table {
    margin-top: 0;
  }

  .jen-builds {
    .cds--tile.chart-duration {
      float: left;
      min-height: 115px;
      margin-right: 5px;
      min-width: 6rem;
    }
  }

  .h5-title {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 22px;
    float: right;
    background: white;
    padding: 5px;
  }

  .tiles3 {
    width: 100%;
    float: left;
    margin-right: 13px;
    padding: 24px;
    &.last-child {
      margin-right: 0px;
    }
  }

  .top5 {
    width: 100%;
    clear: both;
    display: inline-block !important;

    ul li {
      list-style: inside;
      padding-top: 5.3px;
    }

    h5 {
      font-weight: 800;
    }
  }

  .padding-lft {
    padding-left: 0 !important;
  }

  .padding-rgt {
    padding-right: 0 !important;
  }

  .report-title {
    line-height: 1.1rem;
  }

  .vulner {
    h3 {
      color: red;
    }
  }

  ul.action-icons li {
    float: left;
    margin-right: 5px;
    cursor: pointer;

    &.enable {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .cicdModal {
    .cds--modal-header__label {
      font-size: 1rem;
    }

    .tech {
      margin: 15px 0;

      .box {
        width: 100%;
        height: 10px;
        background: #f1e05a;
        display: block;
        border-radius: 10px;
      }
    }

    .cds--modal-container--lg {
      width: 80% !important;
    }

    .cds--tile {
      background-color: #f6f2ff;
      font-size: 0.75rem;
      margin-top: 0;
      min-height: 2rem;
      padding: 10px;
    }

    .copy-clip {
      height: 400px;
      max-width: 100%;

      .cds--snippet-container {
        width: 100%;
        max-height: 100% !important;
        min-height: 100% !important;
      }
    }

    .cds--modal-content {
      margin-bottom: 10px;
      padding-top: 15px;

      .padding-0 {
        padding: 0;

        .cds--tile {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .rotateIcons {
    animation: rotation;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .passive-modal {
    .cds--snippet--multi {
      max-width: 60rem;

      pre {
        code {
          font-size: 0.8rem;
        }
      }
    }
  }

  .analyze {
    .cds--modal-content {
      margin-bottom: 10px;
    }

    .cds--modal-container {
      width: 90% !important;

      .ana-res {
        background-color: #f4f4f4;
        height: 400px;
        margin-top: 0;

        ul {
          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .mdl-collaborate {
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 0;
  }

  .model-list {
    display: flex;

    li {
      float: left;
      width: 48%;
      margin-right: 10px;

      .cds--tile {
        margin-top: 0;
        background-color: #edf5ff;
      }
    }
  }

  .pipeline-activity--row {
    margin-top: 1rem;
  }
}