.alert--count{
    color: #0062fe;
    position: absolute;
    bottom: 14px;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        font-size: 12px;
    }
}

.alert--wrapper{
    p{
        font-size: 12px;
    }
}

.tile--metrics{
    display: flex;
    gap: 16px;
    margin-left: 0;
}