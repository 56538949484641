.att-dash {
    .cds--tile {
        margin-top: 5px;
    }

    .filter-date {
        position: relative;

        .cds--date-picker {
            position: absolute;
            top: -20px;
            right: 16px;
        }
    }

    .portfolio-fss {
        background: none;
        border: none;
        float: left;
        position: absolute;
        right: 310px;
        top: 5px;

        li {
            font-size: 0.85rem;

            svg {
                vertical-align: middle;
                margin-left: 1px;
            }

            &.Monthly {
                pointer-events: none;
                opacity: 0.3;
            }

            &.active {
                color: #0f62fe;
                background: none;
                font-weight: 600;
            }
        }
    }

    .radio-btn {
        width: 24px;
        float: left;
    }

    .clickable-tile-portfolio-att {
        height: 150px;
        min-height: 8rem;
        float: left;

        .flow-title {
            font-weight: 600;
        }

        .flow-value {
            font-weight: 400;

            span {
                font-size: 0.7rem;
                color: #161634;
            }

            &.red {
                color: #da1e28;
            }

            &.green {
                color: #24a148;
            }
        }

        .arrow-icon {
            bottom: 5px;
            fill: #4589ff;
        }

        .anamoly {
            width: 40%;
            float: right;
            text-align: end;

            h3 {
                font-weight: 400;
            }

            &.green {
                h3 {
                    color: #24a148;
                }
            }

            &.red {
                h3 {
                    color: #da1e28;
                }
            }
        }

        &.margin-lft {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }


        .cds--link {
            height: 100%;
            border-radius: 5px;
            box-shadow: 1px 1px #ccc;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .title {
            float: left;
            width: 60%;
        }

        ul.status {
            margin-top: 5px;
            display: inline-block;

            li {
                float: left;
                width: 50%;

                .red {
                    color: #da1e28;
                }

                &:nth-child(2),
                &:nth-child(4) {
                    &>div {
                        width: 72%;
                        text-align: end;
                    }
                }

                &>div {
                    float: left;
                    width: 58%;
                    margin-right: 2px;
                    line-height: 1.3rem;
                }

                span {
                    font-size: 1rem;
                }
            }
        }

        .skeleton {
            width: 100%;
            margin-top: 5px;
        }
    }

    .lft-topo,
    .rgt-table {
        float: left;
        width: 100%;
        height: 100%;
        margin-top: 5px;
        padding: 10px;
    }

    .lft-topo {
        h4 {
            color: #0043ce;
            font-weight: 500;
        }

        .cds--form-item {
            width: 100%;
            align-items: center;

            .cds--label {
                display: block;
                width: 30%;
            }
        }

        div#force-grp {
            margin-top: 20px;
        }

        .cds--structured-list {
            margin-top: 30px;
            background-color: #eef3fe;

            .cds--structured-list-td {
                background-color: #eef3fe;
                border-top: 1px solid #fff;
                padding: 15px;
            }
        }

        ul.jobs-legend {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            width: 100%;

            h5 {
                color: #343131;
                font-weight: 500;
            }

            li {
                float: left;
                margin-right: 5px;
                font-size: 0.75rem;

                svg {
                    vertical-align: middle;
                }

                span {
                    border: 2px solid #343131;
                    border-radius: 10px;
                    display: block;
                    float: left;
                    height: 14px;
                    margin-left: 2px;
                    margin-right: 6px;
                    width: 14px;
                }
            }
        }
    }

    .rgt-table {
        .title {
            display: flex;
            width: 100%;
            flex-flow: row-reverse;
        }

        ul.portfolio-fss {
            float: right;
        }

        .carbon--data--table {
            margin-bottom: 10px;
            margin-top: 0;
        }

        .cds--toolbar-content {
            h5 {
                line-height: 4rem;
                font-weight: 600;
                margin-right: 10px;
                float: left;
                width: 80%;
            }
        }
    }

    .status-sec .cds--tile {
        width: 15%;
        float: left;
        margin-right: 10px;
    }

    .desc-tooltip {
        .cds--tooltip {
            width: 80%;
        }

        svg {
            vertical-align: middle;

            &.red {
                fill: #da1e28;
            }

            &.yellow {
                fill: #ff832b;
            }
        }

        span.cds--popover-content.cds--tooltip-content {
            font-size: 0.8rem;
            max-width: fit-content;
            padding: 10px;
        }
    }

    @keyframes blinker {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    .workflow {
        color: #0f62fe;
        font-size: 0.8rem;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .workflow-lft {
        h6 {
            font-weight: 500;
        }

        .radio-2 {
            margin-top: 2px;
        }
    }

    .rgt-padding {
        padding-right: 0;
    }

    .lft-padding {
        padding-left: 0;
    }

    .workflow-de {
        .cds--tile {
            margin-top: 10px;
            display: inline-block;
            width: 100%;

            ul.job_wrk_details {
                float: right;
                width: 50%;
                height: 100%;

                li {
                    width: 100%;
                    display: inline-block;
                    background-color: #fafafa;

                    .observedtime {
                        vertical-align: sub;
                        margin-top: 4px;
                        display: inline-block;
                    }

                    .cds--tree-node__label span {
                        font-weight: 600;
                    }

                    .cds--tree-node__label__details,
                    .child_task {
                        display: block;
                        width: 100%;
                        font-size: 0.7rem;

                        .first-row {
                            position: relative;

                            h6 {
                                float: left;
                            }

                            .some-class {
                                position: absolute;
                                top: -5px;
                                right: 20px;
                            }
                        }

                        .second-row {
                            display: inline-block;
                            clear: both;
                            width: 100%;
                            margin-top: 5px;

                            h6 {
                                float: left;
                            }
                        }
                    }
                }

                .some-class.yellow {
                    background-color: #f1c21b !important;
                    color: #7c630d;
                }
            }
        }
    }

    .cds--content-switcher {
        width: 100% !important;
    }

    .top-workflow-data {
        .cds--structured-list-td {
            padding-bottom: 0.5rem;

            &:first-child {
                width: 2rem;
            }

            .workflow-err {
                color: #da1e28;
            }
        }

        .db-job-tooltip {
            padding-left: 15px;
            margin-top: 5px;

            .workflow {
                color: #ff832b;
            }
        }
    }

    .action-icon {
        li {
            float: left;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 18px;
            }
        }
    }

    .cds--modal {
        width: 100% !important;
    }

    .disable {
        cursor: default;
        opacity: .1;
    }

    .job-exe {
        .cds--form-item {
            margin-top: 20px;
        }

        .cds--data-table-header {
            padding: 5px;

            .cds--data-table-header__title {
                font-size: 0.9rem;
            }
        }

        .dot-icon {
            fill: #24a148;
            vertical-align: middle;
            float: left;

            &.red {
                fill: #da1e28;
            }

            &.yellow {
                fill: #ff832b;
            }
        }

        .job {
            font-size: 0.65rem;
        }

        .notes {
            font-size: 0.75rem;
        }

        .cds--select-input {
            background: #f7f6f6;
        }

        .cds--search {
            width: 50% !important;
            background: #f7f6f6;
            height: 2.5rem !important;
            margin-top: 8px;
            margin-left: 5px;
        }

        li.stat-color {
            width: 18px;
            height: 16px;
            border: 1px solid #ccc;
            float: left;

            &.done {
                background: #24a148;
            }

            &.error {
                background: #da1e28;
            }

            &.start {
                background: #f1c21b;
            }
        }
    }

    .details.cds--data-table-container {
        margin-top: 60px;

        th,
        td {
            background-color: #edf5ff;
        }
    }

    .modal {
        .center-alignment {
            display: block;
        }

        .stat {
            text-transform: capitalize;

            &.Yet {
                background-color: #ff832b;
                color: #392c03;
                text-transform: lowercase;
            }
        }

        .actul {
            color: #da1e28;
        }

        .ul-mdl {
            li {
                float: left;
                width: 100%;
                margin-top: 10px;

                span {
                    font-weight: 600;
                    float: left;
                    width: 18%;
                }

                p {
                    width: 79%;
                    float: right;
                    position: relative;

                    &::before {
                        content: "-";
                        position: absolute;
                        left: -25px;
                    }

                    svg {
                        vertical-align: sub;
                    }
                }
            }
        }
    }

    .jobs-len {
        width: 22%;
        position: relative;
        height: 100%;
        float: right;

        .toggle {
            width: 100%;
            display: inline-block;

            .cds--toggle {
                float: left;
                margin-top: 5px;
                margin-right: 10px;
            }
        }

        .cds--chart-holder {
            clear: both;
            margin-top: 100px;

            p {
                position: unset;
            }
        }

        ul {
            margin-top: 10px;

            &.jobs-det {
                margin-top: 20px;
                float: right;
                clear: both;

                li {
                    text-decoration-style: dotted;
                    margin-bottom: 6px;

                    span {
                        color: #000cff !important;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }

            li:last-child span {
                color: red;
            }
        }

        p {
            bottom: 10px;
            position: absolute;
            right: 0;
            clear: both;
            font-size: 0.75rem;
        }
    }

    #daily-grp {
        #titleTxt {
            font-weight: 600;
        }

        text {
            font-weight: 400;
        }

        margin-top: 15px;
        text-align: center;
    }
}

.dashboard--margin {
    margin-top: 10px;
}

.topology-graph--div {
    float: left;
    width: 25%;

    h4 {
        span {
            color: #161616;
            font-size: 0.85rem;
        }
    }
}

.topology-graph--div-time {
    float: left;
    width: 50%;
    &.no-jobs{ 
        margin-top: 10%;
        text-align: center;
    }
}

.node-details--message {
    font-size: 0.65rem;
    padding: 0 10px;
}

.head-audit {
    display: flex;
    flex-direction: row;
    h4 {
        width: 80%;
    }
    .cds--toggle {
        display: inline-flex;
        width: 20%;
    }
}

.daily.false {
    display: none;
}

.audit-table.false {
    display: block;
}

.audit-table.true {
    display: none;
}

.daily.true {
    display: block;
}

.varient-in {
    &.red {
        color: #da1e28;
        font-weight: 600;
    }

    &.orange {
        color: #ff832b;
        font-weight: 600;
    }
}

ul.portfolio-fss li {
    border-right: 1px solid #ccc;
    cursor: pointer;
    float: left;
    padding: 8px 9.2px;
}

.disable--button{
    .create--button{
        pointer-events: none;
        opacity: 0.3 !important;
    }
}