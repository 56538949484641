@import "../../../Css/_variable.scss";

.filter--page {

    background-color: #ffff;

    .alert-filters {
        display: flex;
        gap: 50px;
    }

    .cds--dropdown,
    .cds--dropdown:hover {
        min-height: 48px;
        background-color: #f4f4f4;

        #downshift-40488-toggle-button {
            .cds--list-box__label {
                color: #E0E0E0;
            }
        }
    }

    .multi-select-filter-alert {

        .cds--multi-select--filterable,
        .cds--list-box__menu-item {
            background-color: #f4f4f4;
            min-height: 48px;
        }

        .cds--label {
            font-size: 0.75rem;
            font-weight: 400;
            letter-spacing: 0.32px;
            color: #000;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
        }

    }

    .cds--label {
        color: black;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        height: 26px;
        color: $gray100;
        font-family: "IBM Plex Sans";
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px;
    }

    .cds--tag--filter {
        padding-right: 0px !important;
    }

    .multi-select-row {
        display: flex;
        justify-content: space-between;
    }

    .multi-select-row-alert {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 25px;
    }

    .multi-select-filter-alert {
        width: 15vw;
        //width:250px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .multi-select-row-2 {
        display: flex;
        margin-right: 60 px;
    }

    .font-family {
        font-family: "IBM Plex Sans";
    }

    .toggle-filter {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
    }

    .show-incident {
        margin-top: 14px;
    }

    .bottom-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;

        .cds--btn--ghost {
            width: 70px !important;
            min-height: 3rem !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;
            font-size: 1rem;
            font-weight: 400;

        }

        .cds--btn--ghost:hover {
            background-color: #f4f4f4;
        }
    }
}