@use "@carbon/motion";

.ALM-Dashboard {
  .cds--aio--grouped-bar {
    .bar {
      transition: opacity motion.$duration-fast-01;
    }
  }
  .h4-title {
    font-weight: 400;
    padding: 7px 12px;
    background-color: white;
  }

  .inference {
    color: #4589ff;
    margin-top: 2px;
    font-size: 0.75rem;
    position: relative;
    background-color: white;
    padding: 15px;

    span {
      color: #161616;
      line-height: 0.95rem;
    }

    svg {
      position: absolute;
      right: 5px;
      top: 2px;
    }
  }

  .activeTiles {
    float: left;
    width: 48%;
    margin-right: 10px;
    min-height: 20px !important;
    padding: 10px !important;
    margin-top: 24px;
    margin-left: -15px;
    border-top: 2px solid #0f62fe;
  }

  .inActiveTiles {
    float: right;
    width: 48%;
    min-height: 20px !important;
    padding: 10px !important;
    margin-top: 24px;
    margin-left: -15px;
    background-color: #e0e0e0 !important;
  }

  .progress-indic {
    margin-top: 30px;
    padding: 0 40px;

    .cds--progress-line {
      background-color: #e0e0e0 !important;
    }

    .active-pro {
      .cds--progress-line {
        background-color: #0f62fe !important;
      }
    }
  }

  h5 {
    font-weight: 400;
  }

  .sec-row {
    margin-top: 20px;

    h4 {
      font-weight: 400;
    }

    .table-ske th {
      background: #393939;
      color: white;
    }

    .cds--data-table-container {
      margin-top: 42px;

      .cds--table-sort {
        background: #393939;
        color: white;
      }
    }

    .left-tiles {
      h5 {
        font-weight: 400;
      }
    }

    .cds--tab-content {
      background-color: white;

      .cds--tile.chart-container {
        background-color: hsla(0, 0%, 80%, 0.23) !important;
      }
    }

    .tabs-info {
      background-color: white;
    }
  }

  .cds--form-item.radio-grp {
    padding: 10px 0;
  }

  .viewAll-btn {
    margin: 10px;
    padding: 5px;
    min-height: 0;
    float: right;
    position: relative;
    top: 45px;
  }
}
