/** @format */

@import "../../../Css/_variable.scss";

.skeletonPlaceholder--it-health {
  padding: 10px !important;

  .cds--skeleton__placeholder {
    height: $spacing-14 !important;
    margin-left: $spacing-04;
    width: 100% !important;
  }
}

.skeletonPlaceholder--it-health-short {
  margin-top: 1rem !important;
  margin-left: 1rem !important;
  .cds--skeleton__placeholder {
    height: 5rem !important;
    width: 100% !important;
    margin-top: 1rem;
  }
}


.skeleton__placeholder--default {
  .cds--skeleton__placeholder {
    height: $spacing-15 !important;
    margin-left: $spacing-00;
    width: 100% !important;
  }
}

.skeleton__placeholder--table {
  .cds--skeleton__placeholder {
    height: $spacing-15 !important;
    margin-left: 2rem;
    margin-top: 1rem;
    width: 96% !important;
  }
}

.text-title {
  height: $spacing-09;
}

.node_dark_orange {
  border: 2px solid darkorange;
}

.node_orange {
  border: 2px solid orange;
}

.node_red {
  border: 2px solid red;
}

.html-spinner_no-pointer {
  width: $spacing-10;
  height: $spacing-10;
  border-top: 2px solid white;
  border-radius: 50%;
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.html-spinner {
  width: $spacing-10;
  height: $spacing-10;
  border-top: 2px solid white;
  border-radius: 50%;
  transition-property: transform;
  -moz-transition-property: transform;
  -webkit-transition-property: transform;
  -o-transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scrollable-table {
  padding-top: 1rem;

  .cds--data-table tr {
    display: table;
    table-layout: fixed;
  }

  .cds--data-table tbody {
    display: block;
    height: $spacing-15;
    overflow-y: auto;
  }

  .cds--data-table {
    display: flex;
    table-layout: fixed;
    flex-flow: column;
  }
}

.topology-graph {
  svg {
    margin-top: 1em;
    margin-left: 1em;
  }

  .cds--cc--edge__inner {
    stroke: lightgrey;
  }

  .cds--cc--shape-node__body {
    left: unset !important;
    top: calc(100% + 0.125rem) !important;
  }

  .overflow-visible {
    overflow: visible;
  }

  .font-italic {
    font-style: italic;
  }

}

#marker {
  fill: lightgrey;
}

.cds--breadcrumb {
  margin-bottom: $spacing-05;
}

.chart-loader__layer {
  background: white;
  padding: 0 2%;
  position: absolute;
  right: 35%;
  top: 45%;
  z-index: 1;
  border: 1px solid lightgray;
}

.data_unavailable--layer {
  background: white;
  padding: 2%;
  text-align: center;
}

.data_unavailable--layer-short {
  background: white;
  text-align: center;
  height: 3rem;
  padding-top: 0.5rem;

}

.chart-description {
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 7px;
  color: #525252;
  font-size: 12px;
  line-height: 1px;
  font-style: italic;
}