/** @format */

@import "../Css/_variable.scss";

html,
body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: auto;
}

#controltower {
  background: #f4f4f4 !important;
  height: 90vh;
  width: 100%;
  margin-top: $spacing-09 !important;
  overflow-y: auto;

  // h5 {
  //   font-size: 1rem !important;
  // }

  .cds--breadcrumb {
    padding-left: 0rem !important;
  }

  .content-div {
    .bx--col-lg-3 {
      display: table;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.cds--header~.cds--content {
  margin-top: $spacing-05 !important;
}

//Blue Theme

.clickable--tile-wizard .cds--tile--clickable {
  height: $spacing-14;
  margin-top: $spacing-05;
}

img.hover-image:hover {
  transform: scale(1.2, 1.2);
}

.healthy {
  fill: #42be65;
}

.unhealthy {
  fill: #fa4d56;
}

.app-performance--tile {
  height: 20rem !important;
}

.clickable--tile .cds--tile--clickable {
  // height: $spacing-13;
  position: relative;
  display: grid;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;

  .disable-tile {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background: #cccccc52;
  }

  .cds--tile {
    width: 3rem !important;
  }
}

.clickable--tile--icpsa {
  height: $spacing-13 !important;
  position: relative;
  display: grid;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;

  .disable-tile {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background: #cccccc52;
  }

  .cds--tile {
    width: 3rem !important;
  }
}

.layer__tiles {
  padding-top: $spacing-06;
}

.tile-pagination.cds--pagination {
  border-top: none;
  margin-top: $spacing-04;
}

.cds--content {
  padding: $spacing-07 0 0 0;
}

.carbon--icons-20 {
  height: 1.25rem;
}

.icon--white {
  filter: invert(1);
}

.report-title {
  font-weight: bold;
}

.event-loader {
  justify-content: center;
  padding: 20%;
}

.tile-icon {
  opacity: 50%;
  height: $spacing-07;
  width: $spacing-08;
}

.platform-tile-icon {
  height: $spacing-09;
  width: $spacing-07;
  opacity: 50%;
}

.container-widget {
  .report-title {
    padding-top: 1%;
  }

  p {
    font-weight: 400 !important;
  }

  .cds--css-grid-column {
    margin-right: $spacing-00;
  }
}

.collaborate-icon {
  height: 1.5rem;
  position: absolute;
  left: 32rem;
  top: 44rem;
  cursor: pointer;
}

.arrow-icon {
  bottom: $spacing-05;
  position: absolute;
  right: $spacing-05;
  height: $spacing-06;
}


@mixin common-icon {
  bottom: $spacing-05;
  right: $spacing-05;
  height: $spacing-05;
}

.launch-icon {
  @include common-icon;
}

.info-icon {
  @include common-icon;
  height: 3rem;
}

.error-short-icon {
  @include common-icon;
  height: 2rem;
  vertical-align: middle;
}

.error-icon {
  @include common-icon;
  height: 3rem;
}

.table-icon {
  height: $spacing-12;
  margin-left: $spacing-12;
  margin-top: $spacing-07;
}

.container-multichart {
  .cds--css-grid-column {
    margin-right: $spacing-00;
  }
}

.container-multichart__github {
  .cds--css-grid-column {
    margin-right: 0px;
  }

  .cds--modal.is-visible .cds--modal-container {
    background: #f4f4f4;
  }

  .cds--structured-list {
    background: white;
  }

  .dynamic-table-heading {
    text-align: left;
    font-weight: 600;
  }
}

.app-performance--piechart {
  .cds--cc--layout-row {
    display: none !important;
  }
}

.cds--tile {
  margin-top: $spacing-05;
}

.cds--accordion {
  margin-top: $spacing-03;
}

.report-update__button {
  float: right;
  width: 100%;
  margin-top: $spacing-06;
}

.font-italic {
  font-style: italic;
}

.overflow-visible {
  overflow: visible;
}

.skeleton-placeholder {
  width: 100%;
  height: $spacing-10;
}

.description-paragraph {
  padding-top: $spacing-05;
}

.table-launch__tile {
  height: 7.2rem;

  .report-title {
    font-weight: 600;
  }

  .text-title {
    height: 2rem;
  }
}

.modal-content__title {
  font-weight: bold !important;
  font-size: $spacing-05 !important;
  margin-bottom: $spacing-05;
  margin-top: $spacing-02;
}

.event-status:hover {
  text-decoration: underline;
  cursor: pointer;
}

.event-status__anomaly {
  color: red;
}

.event-status__fault {
  color: orange;
}

.event-status__error {
  color: darkorange;
}

.cds--modal-container {
  background: #f4f4f4;
}

.iframeDiv {
  width: 100%;
  height: 56rem;
}

.node__nocursor {
  .cds--cc--shape-node--button {
    cursor: auto;
  }
}

.sla-donut__3 {
  .cds--cc--donut {
    margin-left: 5rem !important;
  }
}

.error-message {
  color: red;
  font-weight: 600;
  font-size: 1.4rem;
  vertical-align: middle;
}

.genai--accordian {
  .cds--accordion__item--active .cds--accordion__content {
    padding-top: 1.5rem !important;
  }

  .cds--accordion__content {
    padding-right: 0% !important;
  }
}

.app-performance--tablebody {
  height: auto !important;
}

.cds--data-table td {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.prca {
  white-space: nowrap;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.prca:hover {
  height: auto;
  overflow-y: none;
  white-space: initial;
  text-overflow: initial;
}

.ip-address {
  .cds--tag {
    max-width: 95%;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// PlatformInsightsTilesComponent

.tile--unclickable {
  background-color: $disabled_tile_background !important;
  height: $spacing-13;
}

@mixin overflow--ellipsis {
  bottom: $spacing-05;
  right: $spacing-05;
  height: $spacing-05;
}

.text-on-tile__primary_title {
  @include overflow--ellipsis;
  margin-right: $spacing-08;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  bottom: $spacing-05;
  height: $spacing-07;
}

.text-on-tile__small {
  font-size: small;
}

.text-on-tile__secondary_title {
  color: $text-secondary;
  font-size: $spacing-04;
}

.text-on-tile__secondary_content {
  font-size: $spacing-05
}

.text-on-tile__secondary_content_columns {
  margin-left: $spacing-00;
}

.text-on-tile__secondary_content_columns_title {
  font-size: $spacing-04;
  color: $text-secondary;
  font-weight: 400;
  margin-left: -$spacing-05;
  text-decoration: underline;
}

.text-on-tile__secondary_tag {
  margin-bottom: $spacing-03;
}

.text-on-tile__right-top {
  float: right;
  font-weight: bold;
  font-size: $spacing-04;
}

.text-on-tile__secondary_status-icon {
  position: absolute;
  top: $spacing-03;
  right: $spacing-03;
}

.col-text-on-tile {
  margin-left: $spacing-00;
}

// AppPerformanceTilesComponent
.cursor__pointer {
  cursor: pointer
}

.table--col--highlight {
  color: $text-on-color;
  padding-left: $spacing-03;
  padding-right: $spacing-03;
  margin-left: $spacing-03;
}

.issue-background-color__red {
  background: red;
}

.issue-background-color__darkorange {
  background: darkorange;
}

.issue-background-color__orange {
  background: orange;
}

.issue-background-color__blue {
  background: blue;
}

.text-on-tile__secondary_tag--portfolio {
  margin-bottom: $spacing-03;
}

.button__icon {
  float: right;
}

.icon__color--not-executed {
  fill: $dark_green;
}

.icon__color--in-progress {
  fill: $red;
}

.icon__color--success {
  fill: $dark_green;
}

.icon__color--failure {
  fill: $red;
}

.icon-on-tile__table {
  height: $spacing-04;
}

// TraceSummary

.table-padding {
  padding-top: $spacing-05;
}

// TraceDetails

.common-top-margin {
  margin-top: $spacing-05;
}

.common-top-margin__modal {
  margin-top: $spacing-03;
}

.monitoring-tile-icon {
  height: $spacing-07;
  padding-left: $spacing-03;
  margin-top: $spacing-03;
}

// SimilarRCATickets

.priority-background-color__red {
  background: red;
}

.priority-background-color__pink {
  background: pink;
}

.priority-background-color__orange {
  background: orange;
}

.priority-background-color__turquoise {
  background: turquoise;
}

.priority-background-color__green {
  background: green;
}

.priority-background-color__blue {
  background: blue;
}

.priority-background-color__gray {
  background: gray;
}

.common-left-margin {
  margin-left: $spacing-05;
}

.width--100 {
  width: 100%;
}

.inline-loading__table {
  margin-top: $spacing-06;
}

.common-text--highlight {
  background-color: $yellow;
}

.table-tool-bar__menu-icon {
  position: absolute;
  right: 10%;
}

.table-cell__nowrap {
  white-space: pre-wrap;
}

.date-picker--margin {
  margin-left: 25%;

  .cds--date-picker.cds--date-picker--single .cds--date-picker__input {
    width: 12.5rem;
  }
}

// utilities

.ml-0 {
  margin-left: $spacing-00;
}

.mt-1 {
  margin-top: 1rem;
}