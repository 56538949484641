.tile- {
    color: #4589ff;
    font-weight: 600;
}

.instrument {
    .report-title {
        text-transform: capitalize;
    }

    p.desc {
        font-size: 0.85rem;
        line-height: 1.1rem;
        margin: 10px 0;
    }

    .ml-modal {
        min-height: 120px;
        height: 232px;
        position: relative;

        .expect {
            position: absolute;
            bottom: 10px;
            font-size: 0.85rem;

            svg {
                vertical-align: sub;
            }
        }

        .model {
            // position: absolute;
            bottom: 10px;
            font-size: 0.85rem;
            font-weight: bold;
            // svg {
            //     vertical-align: sub;
            // }

        }
        .model-data {
            font-size: 0.85rem;
        }
    }

    .insights-hb {
        height: auto;
        padding: 0 !important;
    }

    .cds--accordion__item {
        .cds--accordion__content {
            display: none;
        }
    }

    .cds--accordion__item--active {
        .cds--accordion__content {
            display: block;
            padding-inline-end: 0;

            .cds--tile {
                min-height: 80px;
                margin-top: 0px;
                margin-bottom: 15px;
                box-shadow: 1px 1px #ccc;
                border-radius: 5px;

                .report-title {
                    font-size: 0.9rem;

                    svg {
                        fill: #4589ff;
                        vertical-align: middle;
                    }
                }

                // .arrow-icon {
                //     fill: #4589ff;
                //     bottom: 14px;
                //     float: right;
                //     position: absolute;
                //     right: 14px;
                //     width: 5%;
                // }
            }

        }
    }
}