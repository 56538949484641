.ato-row{
  // .insights-hb{
  //   max-inline-size: 16.6%;
  // }
  position: relative;
  .list-ul {
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
    li {
      width: 50%;
      span {
        font-size: 1rem;
        float: right;
        width: 25%;
        font-weight: 600;
      }
    }
    
  }

  .ato-toggle {
    position: absolute;
    right: 30px;
    top: 20px;
    z-index: 999;
  }

  &.details.cds--row {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .detail-stat {
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
    .success {
      width: 20px;
      height: 20px;
      background: #24a148;
      margin-right: 1px;
      margin-bottom: 1px;
    }

    .fail {
      width: 20px;
      height: 20px;
      background: #fa4d56;
      margin-right: 1px;
      margin-bottom: 1px;
    }
  }

  .cds--tab-content{
    width: 100%;
    .insights-hb{
      float: left;
    }

    .type {
      margin-top: 5px;
      li {
        float: left;
        margin-right: 5px;
        background: #ffafd2;
        padding: 2px 5px;
        border-radius: 5px;
        font-size: 0.75rem;
        text-transform: capitalize;
      }
    }

    .avail{
      span {
        font-size: 1.4rem;
      }
      .green{
        color: #24a148;
      }
    }
  }
  .insights-hb{
    height: fit-content;
  }

  .insights-bus-hb{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // flex: 0 0 14%;
    // max-inline-size: 30%;
    .cds--tile{
      width: 33%;
      // min-inline-size: 14rem;
      &.active{
        background-color: #ccc;
      }
    }
  }
    .cds--tile--clickable{
        min-height: 186px;
    }
    .ato-chart{
        display: flex;
      width: 48%;
      margin-right: 5px;
      .chart-container{
        width: 100% !important;
      }
    }

    .basic-info {
      display: flex;
      align-content: space-evenly;
      width: 100%;
      li {
        width: 34%;
        text-align: center;
      }
    }

    .cds--cc--chart-wrapper text{
      font-size: 25px !important;
    }

}

.business{
  .cds--tile--clickable{
    min-height: 150px;
}
}

.chart-box .chart-container .title{
  padding-right: 0;
}

.chart-box-ato{
  margin-top: 20px;
}

.show-detail.cds--row {
  background: white;
  padding: 10px 0;
  margin-top: 15px;
  .det-data {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    ul {
      width: 23%;
      border-right: 1px solid #ccc;
      margin-right: 15px;
      margin-top: 20px;
      li {
        font-size: 0.9rem;
        margin-bottom: 5px;
        h6 {
          width: 25%;
          display: inline-block;
        }
      }
    }
  }
  .pnc-value-stream .paddingLft {
    padding-left: 1rem !important;
    .cds--tile--clickable{
      min-height: fit-content;
    }
  }
  .pnc-value-stream .paddingRgt {
    padding-right: 1rem !important;
  }
}

.snw-inc {
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  height: 350px;
  overflow-y: auto;
  ul {
    margin-bottom: 15px;
    li {
      span{
        font-weight: 600;
      }
      font-size: 0.85rem;
      margin-bottom: 5px;
      line-height: 1rem;
    }
  }
}

.ato--detail{
  .colum{
    padding: 0.5rem !important;
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
    .cds--tile{
      width: 48%;
      min-height: 40px;
      .basic-info.ato{
        li {
          width: 100%;
        }
        span {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
  .active{
    background-color: #ccc;
  }
}