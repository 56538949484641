@import "_variable.scss";
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: 300;
// }

// p {
//   font-weight: 300;
// }

.fw-normal {
  font-weight: normal;
}

// Type styles
// Productive styles: suffix -01
// Expressive styles: suffix -02

$font-family--code: "IBM Plex Mono";
$font-family--label: "IBM Plex Sans";

$font-size__small: 12px;
$font-size--heading-01: 14px;
$font-size--heading-02: 16px;
$font-size--heading-03: 20px;
$font-size--heading-04: 28px;
$font-size--heading-05: 32px;
$font-size--heading-06: 42px;
$font-size--heading-07: 54px;
$font-size--fluid-display-03: 60px;
$font-size--fluid-display-03: 92px;

$line-height--label-01: 16px;
$line-height--label-02: 18px;
$line-height--para__short: 22px;
$line-height--heading-01: 20px;
$line-height--heading-02: 24px;
$line-height--heading-03: 28px;
$line-height--heading-04: 36px;
$line-height--heading-05: 40px;
$line-height--heading-06: 50px;
$line-height--heading-07: 64px;
$line-height--quote__small: 30px;
$line-height--fluid-display-03: 70px;
$line-height--fluid-display-04: 102px;

$font-weight__regular: 400;
$font-weight__semi-bold: 600;
$font-weight__light: 300;

$letter-spacing--code: .32px;
$letter-spacing--para__short: .16px;
$letter-spacing--heading: 0px;
$letter-spacing--fluid-display: -.64px;

// Utility styles
// for code snippets, labels for captions, helper text, legal copy

// inline code snippets and smaller code elements.
@mixin code-01 {
  font-family: $font-family--code;
  font-size: $font-size__small;
  line-height: $line-height--label-01;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--code;
}

// large code snippets and smaller code elements.
@mixin code-02 {
  font-family: $font-family--code;
  font-size: $font-size--heading-01;
  line-height: $line-height--heading-01;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--code;
}

// field labels in components
// error messages
// captions
// Do not use for body copy
@mixin label-01 {
  font-family: $font-family--label;
  font-size: $font-size__small;
  line-height: $line-height--label-01;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--code;
}

// field lables in components
// error messages
// captions
// Do not use for body copy
@mixin label-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--label-02;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--para__short;
}

// appears below a field title within a component
@mixin helper-text-01 {
  font-family: $font-family--label;
  font-size: $font-size__small;
  line-height: $line-height--label-01;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--code;
}

// appears below a field title within a component
@mixin helper-text-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--label-02;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--para__short;
}

// legal copy in product pages
@mixin legal-01 {
  font-family: $font-family--label;
  font-size: $font-size__small;
  line-height: $line-height--label-01;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--code;
}

// legal copy appearing in webpages
@mixin legal-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--label-02;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--para__short;
}

// Body styles

// short paragraphs < 5 lines
// in components
@mixin body-compact-01 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--label-02;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--para__short;
}

// short paragraphs < 5 lines
// expressive components - button and link
@mixin body-compact-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-02;
  line-height: $line-height--para__short;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// long paragraphs > 4 lines, for productive layouts
// longer body copy in Accordian, Structured list, etc
// always left-aligned
// productive moments within expressive experiences
@mixin body-01 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--heading-01;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--para__short;
}

// long paragraphs > 4 lines, for expressive layouts
// always left-aligned
@mixin body-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-02;
  line-height: $line-height--heading-02;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// Fixed heading styles
// for product pages where multiple containers are used

// component & layout heading
// pairs with body-compact-01
@mixin heading-compact-01 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--label-02;
  font-weight: $font-weight__semi-bold;
  letter-spacing: $letter-spacing--para__short;
}

// smaller layout headings
// pairs with body-compact-02
@mixin heading-compact-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-02;
  line-height: $line-height--para__short;
  font-weight: $font-weight__semi-bold;
  letter-spacing: $letter-spacing--heading;
}

// components and layout headings
// pairs with $body-01
@mixin heading-01 {
  font-family: $font-family--label;
  font-size: $font-size--heading-01;
  line-height: $line-height--heading-01;
  font-weight: $font-weight__semi-bold;
  letter-spacing: $letter-spacing--para__short;
}

// small layout headings
// pairs with $body-02
@mixin heading-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-02;
  line-height: $line-height--heading-02;
  font-weight: $font-weight__semi-bold;
  letter-spacing: $letter-spacing--heading;
}

// component and layout headings
@mixin heading-03 {
  font-family: $font-family--label;
  font-size: $font-size--heading-03;
  line-height: $line-height--heading-03;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin heading-04 {
  font-family: $font-family--label;
  font-size: $font-size--heading-04;
  line-height: $line-height--heading-04;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin heading-05 {
  font-family: $font-family--label;
  font-size: $font-size--heading-05;
  line-height: $line-height--heading-05;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin heading-06 {
  font-family: $font-family--label;
  font-size: $font-size--heading-06;
  line-height: $line-height--heading-06;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin heading-07 {
  font-family: $font-family--label;
  font-size: $font-size--heading-07;
  line-height: $line-height--heading-07;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--heading;
}

// Fluid heading styles
// part of expressive set
// Responsive headings
// Do not use this inside a container

// component and layout headings
@mixin fluid-heading-03 {
  font-family: $font-family--label;
  font-size: $font-size--heading-03;
  line-height: $line-height--heading-03;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin fluid-heading-04 {
  font-family: $font-family--label;
  font-size: $font-size--heading-04;
  line-height: $line-height--heading-04;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin fluid-heading-05 {
  font-family: $font-family--label;
  font-size: $font-size--heading-06;
  line-height: $line-height--heading-06;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--heading;
}

// layout headings
@mixin fluid-heading-06 {
  font-family: $font-family--label;
  font-size: $font-size--heading-06;
  line-height: $line-height--heading-06;
  font-weight: $font-weight__semi-bold;
  letter-spacing: $letter-spacing--heading;
}

// Fluid display styles
// part of Expressive styles
// Do not use these inside a container

// larger paragraphs > 2 lines
@mixin fluid-paragraph-01 {
  font-family: $font-family--label;
  font-size: $font-size--heading-04;
  line-height: $line-height--heading-04;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--heading;
}

// "quote"
@mixin fluid-quotation-01 {
  font-family: $font-family--label;
  font-size: $font-size--;
  line-height: $line-height--quote__small;
  font-weight: $font-weight__regular;
  letter-spacing: $letter-spacing--heading;
}

// "quote"
@mixin fluid-quotation-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-06;
  line-height: $line-height--heading-06;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--heading;
}

// Display
@mixin fluid-display-01 {
  font-family: $font-family--label;
  font-size: $font-size--heading-07;
  line-height: $line-height--heading-07;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--heading;
}

// Display
@mixin fluid-display-02 {
  font-family: $font-family--label;
  font-size: $font-size--heading-07;
  line-height: $line-height--heading-07;
  font-weight: $font-weight__semi-bold;
  letter-spacing: $letter-spacing--;
}

// Display
@mixin fluid-display-03 {
  font-family: $font-family--label;
  font-size: $font-size--fluid-display-03;
  line-height: $line-height--fluid-display-03;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--;
}

// Display
@mixin fluid-display-04 {
  font-family: $font-family--label;
  font-size: $font-size--fluid-display-04;
  line-height: $line-height--fluid-display-04;
  font-weight: $font-weight__light;
  letter-spacing: $letter-spacing--;
}

// -----------------------------------------------------------------------------------------------------------------------------------------------------------//
// ------------------------------------------------------COMMON STYLES FOR ICTE : PRODUCTIVE STYLE -----------------------------------------------------------//
// --------------------------------To be used EVERYWHERE except popups and screens with large text for reading and understanding.------------------------------//
// -----------------------------------------------------------------------------------------------------------------------------------------------------------//


// ----------------------------------------------------Utility styles-------------------------------------------------------//
// for code snippets, labels for captions, helper text, legal copy

// inline code snippets and smaller code elements.
.common-font--code {
  @include code-01;
}

// field labels in components
// error messages
// captions
// Do not use for body copy
.common-font--label {
  @include label-01;
}

// helper text
// appears below a field title within a component
.common-font--help {
  @include helper-text-01;
}

// legal copy in product pages
.common-font--legal {
  @include legal-01;
}

// ------------------------------------------------------Body styles------------------------------------------------------//

// short paragraphs < 5 lines
// in components
.common-font--para__small {
  @include body-compact-01;
}

// long paragraphs > 4 lines, for productive layouts
// longer body copy in Accordian, Structured list, etc
// always left-aligned
// productive moments within expressive experiences
.common-font--para__long {
  @include body-01;
}

// ------------------------------------------------------Fixed heading styles------------------------------------------------------//
// for product pages where multiple containers are used

// component & layout heading
// pairs with .common-font--para__small 
.common-font--heading__para__small {
  @include heading-compact-01;
}

// components and layout headings
// pairs with .common-font--para__long
.common-font--heading__para__large {
  @include heading-01;
}

// component and layout headings
.common-font--heading-03 {
  @include heading-03;
}

// layout headings
.common-font--heading-04 {
  @include heading-04;
}

// layout headings
.common-font--heading-05 {
  @include heading-05;
}

// layout headings
.common-font--heading-06 {
  @include heading-06;
}

// layout headings
.common-font--heading-07 {
  @include heading-07;
}