.topology-graph {
    .cds--cc--shape-node__body {
        left: 0;
    }
}

.event-status {
    text-decoration: underline;
    color: #0f62fe;
    cursor: pointer;
}

.trace {

    .prca {
        p {
            font-size: 0.9rem;
            line-height: 1.1rem;
        }
    }

    .logs {
        background-color: #ffffff;
    }

    .metric {
        h5 {
            float: left;
        }

        p {
            float: right;
        }
    }

    .cds--modal-header {
        .cds--modal-close {
            float: right;
        }
    }

    .cds--accordion__item {
        .cds--accordion__content {
            display: none;
        }
    }

    .cds--accordion__item--active {
        .cds--accordion__content {
            display: block;
            padding-inline-end: 1;
        }
    }

    .cds--accordion__content {
        padding-inline-end: 4%;
    }

    .text-on-tile__right-top {
        float: right;
        font-size: .75rem;
        font-weight: 700;
    }

    .priority-background-color__orange {
        background: orange;
    }

    .common-top-margin {
        margin-top: 1rem;
    }

    .box-tit{
        min-height: 74px;
    }

    .text-tile{
        svg {
            float: right;
        }
    }
}

.crteTick {
    float: left;
}

.note {
    font-size: 0.75rem;
    width: 20%;
    padding-top: 30px;
    padding-left: 5px;
    span {
        font-weight: 600;
    }
}

.summary-tile {
    background-color: #f4f4f4 !important;
    .report-title{
        display: flex;
        p {
            font-weight: bold;
            width: 88%;
        }
        .text-on-tile__right-top {
            float: right;
            font-weight: bold;
            font-size: 0.75rem;
            width: 12%;
          }
    }
    .text-on-tile__small {
        margin: 5px auto;
    }
    .common-top-margin {
        margin-top: 5px;
    }
}

.rca-summary{
    .lft {
        height: 502px;
        background-color: white;
        h6{
            padding-left: 20px;
            padding-top: 10px;
        }
        p {
            height: 100%;
            .cds--form-item{
                height: 100%;
                .cds--text-area{
                    border-block-end: unset;
                }
            }
        }
    }
    .cds--text-area{
        background-color: white !important;
    }
    .recommend-ul {
        background-color: #ffffff;
        padding: 15px;
        li {
            font-size: 0.9rem;
            color: #161616;
            margin-bottom: 15px;
            span {
                font-weight: 600;
            }
        }
    }
}

.summary-tile {
    margin-right: 0;
    .cds--tile--clickable {
        min-height: 80px;
        svg {
            float: right;
        }
    }
}

.bottom-rw {
    margin-top: 12px;
    .cln-btm{
        background-color: white;
        width: 93.5%;
        border-top: 1px solid #ccc;
    }
    .col-btm {
        padding: 15px 0 15px 70px;
        display: flex;
        justify-content: space-evenly;
        align-content: baseline;
        width: 60%;
        float: right;
        .icns {
            width: 60%;
          }
        .icn {
            cursor: pointer;
            .cds--link{
                height: 100%;
            }
            svg {
                vertical-align: top;
                height: 100%;
                }
        
        }
    }
}

.do-graph {
    width: 1450px;
    overflow-x: auto;
  }

  .rca-summary{
    .cds--text-area{
        width: 100%;
    }
  }

  #remed {
    height: 500px;
  }

  .nxt-action {
    margin-top: 10px;
    margin-left: 15px;
    li {
        padding-bottom: 4px;
        list-style-type: initial;
    }
  }

  .text-tile{
    font-size: 1rem;
    color: #0f62fe;
  }


  .rca-summary.cds--subgrid.cds--subgrid--wide{
    margin: 0;
    h6 {
        margin-bottom: 5px;
    }
  }