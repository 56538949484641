
.do-graph {
    .html-spinner_no-pointer{
        width: 64px;
        height: 64px;
        border-top: 2px solid white;
        border-radius: 50%;
    }
    .node_dark_orange{
        border: 2px solid #fa4d56;
    }
    .html-spinner {
        border-top: 2px solid white !important;
        border-width: 3px;
    }
    .node_green {
        border: 2px solid #24a148;
    }
    .orange-icon{
        background-color: #ff832b;
        padding: 2px;
        fill: white;
    }
    .magen-icon{
        background-color: #a56eff;
        padding: 2px;
        fill: white;
    }
    .blue-icon{
        background-color: #4053d6;
        padding: 2px;
        fill: white;
    }
    .green-icon{
        background-color: #198038;
        padding: 2px;
        fill: white;
    }
    .purple-icon{
        background-color: #ee5396;
        padding: 2px;
        fill: white;
    }
     
    
}