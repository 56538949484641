.circle-view {
  .portfolio-fss {
    position: relative;
    right: 0;
    background: #f2f4f8;
    border: 1px solid #ccc;
    border-radius: 10px;
    float: left;
    top: 10px;
  }

  .citi--specific--styles {
    .portfolio-fss {
      height: 2.2rem;
    }

    .refresh-icon {
      margin-left: 0;
      margin-top: 0;
      float: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .refresh-icon {
    float: left;
    font-size: 14px;
    margin-left: 25px;
    margin-top: 10px;

    svg {
      vertical-align: middle;

      &.rotateIcons {
        animation: rotation;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-direction: reverse;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
      }
    }
  }

  .cds--tile--clickable {
    border-radius: 5px;
    box-shadow: 1px 1px #ccc;

    &.red {
      h3 {
        color: #da1e28;
      }
    }
  }

  .clickable-tile-portfolio-att {
    float: left;
    padding-left: 0rem;
    margin-bottom: 15px;

    .title {
      width: 54%;
    }

    &:nth-child(2) {
      padding-right: 0;
    }
  }

  .clus-app {
    display: inline-flex;
    float: right;

    li {
      padding-left: 4px;

      h4 {
        float: left;
        font-size: 0.9rem;
      }

      span {
        font-size: 1rem;
        display: inline-block;
        margin-left: 2px;
        color: #0062ff;
        font-weight: 600;
      }
    }
  }

  .sec-row {
    // display: inline-flex;
    clear: both;
    margin-top: 8px;
    width: 100%;
    display: inline-block;

    ul.host {
      float: left;
      margin-right: 50px;
      width: auto;
      display: inline-flex;
      flex-direction: column;
      font-size: 0.8rem;

      span {
        float: right;
        margin-left: 20px;
      }
    }

    ul.session {
      float: left;
      width: 15%;
      display: inline-flex;
      flex-direction: column;
      margin-right: 50px;

      li {
        font-size: 0.8rem;

        h6 {
          font-weight: 600;
        }

        span {
          float: right;
          margin-left: 12px;
        }
      }
    }

    ul.status {
      margin-top: 0 !important;
      width: 40%;
      // float: right;

      li {
        &:first-child {
          width: 100%;
        }

        // &:nth-child(2) {
        //     width: 100%;
        // }

        line-height: 1rem;
        float: left;
        width: 50%;
        font-size: 0.8rem;

        h6 {
          font-weight: 600;
        }

        span {
          font-size: 0.9rem;
          line-height: 1.1rem;
          float: right;
          margin-right: 10px;

          &.red {
            color: #da1e28;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.circle-dash {
  ul.circleLegend {
    width: 100%;
    margin-top: 10px;
    display: inline-block;

    li {
      float: left;
      margin-right: 4px;

      svg {
        vertical-align: sub;
      }
    }
  }

  .padding-rgt {
    padding-right: 0rem;
  }

  .padding-lft {
    padding-left: 0rem;
  }

  ul.action-icons li {
    float: left;
    margin-right: 5px;
  }

  .noRcrds {
    text-align: center;
    margin-top: 50px;
  }
}

.cluster-title {
  height: 69px;
}

.networks {
  .cds--tile {
    width: auto;
    float: left;
    margin-right: 15px;

    span {
      width: 100%;
      display: block;
      font-size: 2rem;
    }

    h4 {
      line-height: 1.2rem;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  .tileActive {
    background-color: #ccc;
  }
}

.citi-details {
  margin-bottom: 15px;

  .cds--tile--clickable {
    height: 100%;

    .title {
      float: left;

      h4.flow-title {
        font-weight: 600;
        line-height: 1rem;
        margin-bottom: 10px;
      }
    }

    ul.clus-app {
      float: right;

      li {
        h4 {
          float: left;
        }

        span {
          margin: 3px 4px 0;
          display: inline-block;
          font-size: 1.2rem;
          color: #4589ff;
        }
      }
    }

    .arrow-icon {
      fill: #0062ff;
    }
  }

  .sec-row {
    width: 100%;
    margin-top: 5px;
    clear: both;

    h4 {
      float: left;
      width: 100%;
      font-weight: 500;
      font-size: 1.8rem;

      &.red {
        color: #da1e28;
      }

      &.green {
        color: #198038;
      }
    }

    ul.host {
      float: left;
      margin-right: 50px;
      width: auto;
      display: inline-flex;
      flex-direction: column;
      font-size: 0.8rem;

      span {
        float: right;
        margin-left: 20px;
      }
    }

    .session {
      float: left;
      width: 32%;

      h6 {
        font-weight: 600;
      }

      .service {
        >div {
          float: left;
          display: flex;
        }
      }
    }

    .status {
      width: 43%;
      float: right;

      li {
        .red {
          color: #da1e28;
        }

        &:first-child {
          font-weight: 600;
          width: 100%;
        }

        float: left;
        width: 44%;
        margin-right: 10px;

        h6 {
          font-weight: 600;
        }

        div {
          float: left;
          margin-right: 2px;
        }

        span {
          float: right;
        }
      }
    }
  }
}

.citi_analyze {
  .cds--snippet--multi {
    background-color: black;
    color: white;
    max-width: 65rem;
  }
}

.calcul {
  //float: right;

  .cds--form-item {
    /* background-color: #dce1ea; */
    padding: 10px;
    /* position: absolute; */
    right: 0;
    top: 37px;
    width: auto;
    z-index: 999;
    float: left;

    .cds--dropdown {
      background: #e0e0e0;
    }
  }
}

.custom-date {
  bottom: -15px;
  left: 125px;
  position: absolute;
  width: 60%;
  font-size: 0.75rem;
}

.counts--wrapper {
  p {
    font-size: 14px;
    font-weight: 400;
  }
}

span.red {
  color: #da1e28;
  font-size: 0.9rem;
  font-weight: 600;
}

.headings--tile {
  h5 {
    font-weight: 600;
  }
}

.mock--radio {
  text-align: center;
  position: relative;

  h3 {
    font-weight: 600;
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
  }

  svg {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.arrow-icon {
  fill: #0062ff;
}


.cds--modal-container {
  position: relative;

  .notify-mail {
    position: absolute;
    right: 110px;
    top: 0;
  }
}

.tiles--wrapper {
  display: flex;
  gap: 10px;
}

.recentTime {
  font-size: 0.8rem;
  float: right;
}

ul.session-log {
  background-color: #fff;
  padding: 10px;

  li {
    clear: both;

    h5 {
      font-size: 0.75rem;
      width: 25%;
      float: left;
    }
  }
}

.retry{
  color: #0062ff;
  text-decoration: underline;
}

.session_link {
  color: #0062ff;
  text-decoration: underline;
  cursor: pointer;
}