.page-401{
    height: 80vh;
    align-items: center;
    h1{
        font-weight: 400;
        font-size: 2rem;
        line-height: 1.25;
        margin-bottom: 0.75rem;
    }
    p{
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 5px;
    }
}
.error-svg {
    width: 60%;
    float: left;
    margin-top: 10%;
}
.error-desc {
    position: relative;
    top: 200px;
    right: 120px;
}