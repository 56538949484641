.valueStream {
    .margin-lft {
        margin-left: 0;
    }

    .Kenvue {
        .cds--tile--clickable {
            height: 145px;

            .flow-title {
                float: left;
                margin-right: 5px;
            }

            .sec-col {
                float: right;
                text-align: center;
            }
        }
    }

    .cds--tile--clickable {
        border-radius: 5px;
        box-shadow: 1px 1px #ccc;
    }

    .sec-col {
        span {
            font-size: 1.5rem;

            &.green {
                color: #42be65;
                font-weight: 600;
            }
        }
    }
}

.workflow-view {
    .title-desc {
        line-height: 1rem;
        font-size: 0.75rem;
        margin-top: 10px;
    }

    .first-row-tile {
        box-shadow: 0.5px 0.5px #ccc;
        min-height: 7.5rem;
        text-align: center;
        position: relative;

        .tile-value0 {
            color: #42be65;
            bottom: 10px;
            font-size: 2.5rem;
            position: absolute;
            text-align: center;
            left: 70px;
        }

        .tile-value1,
        .tile-value2,
        .tile-value3 {
            color: #da1e28;
            bottom: 10px;
            font-size: 2.5rem;
            position: absolute;
            text-align: center;
            left: 70px;
        }

        .tile-value4,
        .tile-value5,
        .tile-value6 {
            color: #0f62fe;
            bottom: 10px;
            font-size: 2.5rem;
            position: absolute;
            text-align: center;
            left: 70px;
        }

    }

    .full-height {
        height: 98%;
    }

    .first-rotate {
        text-align: center;
        margin-top: 40px;
    }

    .bridge-sum {
        text-decoration: underline;
        color: #4646d7;
        cursor: pointer;
    }

    .rotateIcons {
        animation: rotation;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-direction: reverse;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }

    .desc {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 150px;
    }

    .action-icon {
        li {
            float: left;
            margin-right: 10px;
            cursor: pointer;

            .disable {
                opacity: 0.1;
                cursor: default;
            }

            img {
                width: 18px;
            }
        }
    }

    .summary {
        .cds--modal-container--lg {
            width: 96%;
            height: 86%;
        }

        .summary-popup {
            margin: 0 0 20px 0;
            height: 450px;

            .rgt-padding {
                padding-right: 0;
            }

            .lft-padding {
                padding-left: 0;
            }

            .code-row {
                height: 100%;
            }

            .errMsg {
                height: 390px;
                padding: 50px;
                width: 100%;
                text-align: center;
            }

            .summary-Details {
                background-color: #fff;
                margin: 10px;
                padding: 10px;
                overflow-y: auto;
                height: 550px;
                // height: 400px;
                // overflow-y: scroll;

                h5 {
                    font-weight: 600;
                }

                p {
                    font-size: 0.75rem;
                    padding: 5px 5px 5px 20px;
                }
            }

            .text-inp {
                margin: 15px 10px;

                .cds--text-input {
                    background-color: white;
                }

                .cds--form-item {
                    width: 77%;
                    float: left;
                }

                button {
                    margin-top: 24px;
                    min-height: 0.5rem;
                    margin-left: 10px;
                    line-height: 1rem;
                }
            }

            .snip-child {
                .useCase-list>div {
                    height: 425px;
                    overflow-y: auto;
                }

                .cds--tile {
                    font-size: 0.7rem;
                    padding: 10px;
                    background-color: #f6f2ff;
                    min-height: 2rem;
                    margin-top: 0;

                    .cds--tile__checkmark {
                        top: 5px;
                        right: 5px;

                        svg {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }

            .snip-child1 {
                background: #f4f4f4;

                p.errmsg {
                    text-align: center;
                    width: 100%;
                    display: block;
                    padding: 0;
                    margin-top: 100px;
                }
            }

            .back {
                svg {
                    margin-right: 0;
                }
            }

            .mail-content {
                border: 1px solid #eeeeee;
                padding: 10px;
                box-shadow: 0px 0.5px 1px 0px #cccccc;
                border-radius: 2px;
            }

            p.mailcont {
                text-align: center;
                margin: 10px 0;
                width: 100%;
                padding: 0;
                height: 250px;
            }

            .rgt-summary {
                background-color: #f4f4f4;
                padding: 10px;
                height: 410px;
                margin-top: 10px;
                margin-bottom: 10px;

                li {
                    margin-bottom: 10px;
                }

                .slide {
                    margin-top: 10px;

                    .cds--label {
                        margin-bottom: 0;
                    }

                    .cds--slider-container {
                        width: 100%;

                        .cds--slider {
                            min-width: 7rem !important;
                        }
                    }
                }
            }
        }
    }

    .no--right__padding {
        padding-right: 0rem !important;
    }

    .first-row {
        min-height: 7.5rem;
        box-shadow: 0.5px 0.5px #ccc;

        .workflow-title {
            font-weight: 600;
            font-size: 20px;
        }

        h6 {
            font-weight: 600;
            text-align: center;
        }

        span {
            text-align: center;
            width: 80%;
        }

        .sec-4.br,
        .sec-5.br {
            span {
                color: #3e3e3e;
                font-size: 2.5rem;
                position: absolute;
                bottom: 20px;

                p {
                    top: 10px;
                    right: -20px;
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }

            p {
                position: absolute;
                bottom: 0;
                text-align: center;
                width: 80%;
            }
        }
    }

    .sec-1,
    .sec-2,
    .sec-3 {
        span {
            font-size: 2.5rem;
            position: absolute;
            bottom: 20px;
        }
    }

    .sec-1 {
        span {
            color: #198038;
        }
    }

    .sec-2 {
        span {
            color: #da1e28;
        }
    }

    .sec-3 {
        span {
            color: #da1e28;
        }
    }

    .structred-list-table-view {
        padding: 0rem !important;

        .table-row {
            border-bottom: 0px solid gray;

            .cds--structured-list-td {
                padding: 1rem 0.5rem 0.25rem;
                font-size: 0.75rem;
            }

            .error-status {
                color: #da1e28;
            }
        }

        .cust-journey {
            text-align: center;
            font-weight: 600;
            font-size: 1rem;
            color: #0f62fe;
        }

        .last-row-title {
            text-align: center;
            font-weight: 800;
        }

        .sec-row-detail {
            padding: 20px 0px 0px 20px;
            background-color: #198038;
            height: 60px;

            .value {
                text-align: center;
                font-weight: 600;
                color: white;
            }

            .status {
                text-align: center;
                color: white;
                padding-left: 30px;
                font-size: 12px;
            }

            .status-err {
                color: white;
            }
        }

        .sec-row-detail-err {
            cursor: pointer;
            background-color: #ff832b;
        }
    }

    // .chart {
    //   float: left;
    //   width: 70%;
    // }

    .add-border {
        box-shadow: 0.5px 1px #ccc;
        height: 190px;
    }

    .interact {
        height: 175px;
        display: inline-block;
        // margin-left: 20px;
        width: 32%;

        .interaction-title {
            font-weight: 600;
            font-size: 0.9rem;
        }

        span {
            font-weight: 600;
        }

        .sec-4 {
            span {
                color: #198038;
            }
        }

        .sec-5 {
            span {
                color: #da1e28;
            }
        }
    }

    .second-row-table-fss {
        margin-top: 0px !important;

        .table-row {
            .cds--structured-list-td {
                padding: 0.35rem 0.5rem 0.25rem;
            }
        }

        .cust-journey-steps {
            border-right: 0.1px solid #80808024;
            border-left: 0.1px solid #80808024;
        }
    }

    .fss-topology {
        .cds--modal-container--lg {
            max-height: 96%;
            width: 90%;

            .cds--cc--card-node__title {
                font-size: 0.8rem;
            }
        }
    }
}

.clickable-tile-portfolio-fss .cds--tile--clickable {
    height: 135px;
    box-shadow: 1px 1px #ccc;
    border-radius: 5px;

    &:hover {
        background: #cccccc52;
    }

    .arrow-icon {
        bottom: 10px;
        position: absolute;
        float: right;
        right: 10px;
        width: 5%;
        fill: #4589ff;
    }

    .flow-title {
        font-size: 16px;
        font-weight: 600;
    }

    .flow-value {
        font-weight: 600;
        color: #00b050;
    }

    .folw-value-err {
        color: #da1e28;
    }

    .availability-status {
        display: flex;
        padding-top: 5px;
        position: absolute;
        bottom: 15px;
        width: 87%;

        .availBox {
            float: left;
            width: 55%;

            .svg-status {
                margin-top: 4px;
                float: left;
            }

            .status-title {
                font-size: 11px;
                line-height: 20px;
                padding-left: 15px;
            }
        }

        .green-txt {
            color: #198038;
        }

        .red-txt {
            color: #da1e28;
        }
    }

    .recent-bridge {
        width: 45%;
        font-size: 10px;
    }

    .rec-bridge {
        position: absolute;
        bottom: 3px;
    }
}

ul.portfolio-fss {
    background: none;
    border: none;
    font-size: 0.75rem;

    li {
        svg {
            vertical-align: middle;
            margin-left: 2px;
        }

        &.active {
            background: none;
            color: #0f62fe;
        }
    }
}

ul.wid-change {
    width: 70%;
}

.go2 {
    .bridge-sum {
        text-decoration: underline;
        color: #4646d7;
        cursor: pointer;
    }

    margin-top: 10px;

    .box-heading {
        background: white;
        margin-bottom: 1px;
        width: auto;
        font-weight: 800;
    }

    .cds--structured-list-th {
        // padding: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        background: #9bbbf0;
        font-weight: 300;
        font-size: 1rem;
    }

    .remove-up-padding {
        .cds--tile {
            padding-top: 0 !important;
        }
    }

    .cds--tile {
        margin-top: 3px !important;
    }

    .row-sec {
        // margin-top: 2rem;
        border-top: 1px solid black;
        margin-top: 3px;
    }

    .layer {

        // border: 1px solid #ccc;
        .cds--layer-two {
            border-bottom: 1px solid #ccc;
            padding: 5px;

            .example-layer-test-component {
                font-size: 0.7rem;
            }

            span {
                float: right;
                color: #003bff;
            }
        }
    }

    .cds--structured-list-td:nth-child(1) {
        color: #003bff;
    }

    .error {
        .cds--structured-list-td {
            color: red !important;
        }
    }

    .sec-row {
        .tile-box-wrapper {
            padding-top: 0;
            display: flex;

            .tile-box {
                display: inline-block;
                width: 50%;
                border: 1px solid #ccc;

                h5 {
                    padding: 10px;
                    background: #9bbbf0;
                }

                p {
                    text-align: center;
                    padding: 20px;
                    width: 100%;
                    display: block;
                    font-size: 1rem;
                }
            }
        }
    }

    // .recent-head {
    //   margin-top: 47px;
    // }

    .cds--cc--shape-node__title {
        color: #fff;
    }

    .red {
        .cds--cc--shape-node--button {
            background-color: #ff0200;
        }
    }

    .lightgreen {
        .cds--cc--shape-node--button {
            background-color: #70ad47;
        }
    }

    .green {
        .cds--cc--shape-node--button {
            background-color: #00b050;
        }
    }

    .blue {
        .cds--cc--shape-node--button {
            background-color: #4877c4;
        }
    }

    .yellow {
        .cds--cc--shape-node--button {
            background-color: #feff00;
        }

        .cds--cc--shape-node__title {
            color: #000;
        }
    }
}

.popup {
    padding: 1.5px;
    position: absolute;
    background-color: black;
    color: #fff;

    svg {
        fill: #fff;
        cursor: pointer;
    }

    // box-shadow: 0px 10px 16px -3px rgba(0, 0, 0, 0.1);
}

.popup1 {
    padding: 1.5px;
    position: absolute;
    background-color: #c6c6c6;

    color: red svg {
        fill: #fff;
        cursor: pointer;
    }

    // box-shadow: 0px 10px 16px -3px rgba(0, 0, 0, 0.1);
}

.circle-colors {
    .nodes {
        g {
            circle {
                fill: #24a148 !important;
            }
        }

        g:nth-child(3n + 1) {
            circle {
                fill: #a2191f !important;
            }
        }
    }
}

.tree-diag {
    background-color: white;
    padding: 10px;
    height: 100%;
    .toolbar {
        display: none;
    }

    .cds--dropdown {
        width: 40%;
        height: 1.5rem;
    }
}

.b1 {
    border: 1px solid black;
}

.br1 {
    border-right: 1px solid black;
}

.bl1 {
    border-left: 1px solid black;
}

.bt1 {
    border-top: 1px solid black;
}

.bb1 {
    border-bottom: 1px solid black;
}

.padding-for-border {
    padding-bottom: 5.5px;
}

.show-Inference {
    background-color: white;
    height: 214px;
    padding: 10px;
    margin-top: 4px;

    h5 {
        font-weight: 800;
    }

    ol.cds--list--ordered {
        padding-left: 30px;

        li.cds--list__item {
            font-size: 0.85rem;
            color: #2041d1;
        }
    }
}

.tickets {
    .cds--structured-list-td {
        font-size: 0.8rem;
        padding: 10px;
    }
}

.send-btn {
    .rotateIcons {
        animation: rotation;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-direction: reverse;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }

}

.err-circle-elk {
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
    border: 5px solid #fa4d56;
    border-radius: 30px;
    height: 61px;
    position: absolute;
    transition-property: transform;
    width: 61px;
    z-index: 9;
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.shapenode-mod {
    .cds--cc--shape-node--circle {
        height: 50px !important;
        width: 50px !important;
        position: absolute !important;
        top: 6px;
        left: 6px;
        z-index: 99;
    }
}

.err-log {
    background: #fff;
    margin-top: 0;
    padding: 10px;

    &:first-child {
        margin-top: 30px;
    }

    li {
        display: flex;

        h5 {
            float: left;
            font-size: .8rem;
            width: 20%;
            font-weight: 600;
        }

        span {
            float: right;
            width: 80%;
            font-size: 0.8rem;
        }
    }
}

.app-cnt {
    margin-left: 40px;
    margin-bottom: 20px;

    li {
        list-style-type: decimal;
        padding: 5px;
    }
}

.target {
    width: 100%;

    ul {
        float: left;
        position: relative;

        &:first-child {
            width: 50%;
        }

        &:last-child {
            width: 48%;
        }

        li {
            span {
                width: auto;
                display: inline-block;

                p {
                    float: right;
                    margin-top: 14px;
                    font-size: 0.75rem;
                }

                div {
                    line-height: 28px;
                }

                div.green {
                    color: #42be65;
                }

                div.red {
                    color: #da1e28;
                }
            }

            &:first-child {
                span {
                    font-size: 1.8rem;
                }

                h5 {
                    font-size: .75rem;
                    font-weight: 600;
                    line-height: 2px;
                }
            }

            &.targ {
                position: absolute;
                top: 32px;
                left: -8px;

                p {
                    font-size: 0.7rem;
                }

                .cds--tag--sm {
                    span {
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
}

.infra-alert {
    .cds--structured-list-td {
        color: #003bff;
    }

    h5 {
        font-weight: 600;
    }
}

.journey-ul {
    // background: white;
    margin-top: 18px;
    padding-left: 10px;

    ul {
        font-size: 0.85rem;
        margin: 2px 0;
        display: flex;

        &.red {
            background-color: #ffb784;
            font-weight: 600;
            cursor: pointer;
        }

        &:first-child {
            font-weight: 600;
            font-size: 1rem;
        }

        li {
            float: left;
            padding: 2px;

            &.first-li {
                width: 70%;
            }

            &.sec-li {
                width: 15%;
                text-align: center;
            }

            &.the-li {
                width: 15%;
                text-align: center;
            }
        }
    }
}

.polaris {
    .cds--cc--card-node--button {
        border-left: 0;
    }

    .cds--cc--card-node__title {
        transform: rotate(-90deg);
        font-size: 1rem;
        margin-top: 35px;
        position: absolute;
        left: -10px;
    }
}


.vertical_title {
    // transform: rotate(-90deg);
    font-size: 1rem;
    // margin-top: 35px;
    // position: absolute;
    // left: -10px;
}

.card-n.grey {
    background-color: #c6c6c6;
}

.card-n.blue {
    background-color: #82cfff;
}

.card-n.green {
    background-color: #6fdc8c;
}

.inference1 {
    // background-color: #fff;
    background: white;
    width: 100%;
    height: 100px;
     h5 {
         margin-bottom: 5px;
     }
     li {
         font-size: 0.85rem;
        //  text-indent: 0px;
        //  padding-left: 10px;
        //  list-style-type: decimal;
         line-height: 1rem;  
         padding: 5px;   
     }

     svg {
        vertical-align: sub;
     }
 }