@import "../../../Css/_variable.scss";

.dashboard-detail-container .dashboard-roleContainer {
    background-color: #f0f0f0 !important;
    height: auto !important;
}

.dashboard-summary-new {
    height: auto;
    flex-wrap: wrap;
    display: flex;
    row-gap: 16px;
    column-gap: 24px;

    .summary-wrapper {
        background: #fff;
        display: flex;
        flex-direction: column;
        height: 127px;
        justify-content: space-between;
        min-width: 211px !important;
        padding: 16px;
        width: 235px;
        box-shadow: 0 0.5px 0.5px 0 rgba(0,0,0,0.5);

        .dashboard-title {
            color: black;
            font-family: "IBM Plex Sans light";
            font-weight: 600;
            font-size: 21px;            
            line-height: 25px;
            padding-right: 32px;
            padding-top: 8px;
            letter-spacing: 0.3px;
        }

        .title {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
        }
    }

    .tile-container {
        display: flex !important;
        flex-direction: row !important;
        row-gap: 16px;
        flex-wrap: wrap;
        column-gap: 24px;

        .loading-container {
            width: fit-content !important;
            display: flex;
            align-items: center;
            margin: 50px;
            background: transparent;
            box-shadow: none;

            .bx--inline-loading {
                justify-content: center;
            }
        }

        .tiles {
            display: flex;
            flex-direction: column;
            align-items: flex-start;            
            background-color: #fff;
            width: auto;
            justify-content: space-between;
            row-gap: 10px;
            margin-right: -3px;
            max-width: min-content;
            min-width: 235px;
            padding: 16px;  
            height: 127px; 
            box-shadow: 0 0.5px 0.5px 0 rgba(0,0,0,0.5);         
            .tile-title {
                word-break: break-word;
                font-family: "IBM Plex Sans";
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.16px;
                line-height: 20px;
                text-align: left;
                max-width: 192px;
                min-width: 8rem;
                height: 25px;                
            }

            .tile-value {
                padding-top: 16px;
                display: flex;
                justify-content: center;                
                font-size: 42px;
                line-height: calc(3.75vw - 0.6vw);
                letter-spacing: -0.9px;
                font-family: "IBM Plex Sans";
            }

            .statusIndicator {
                display: flex;
                align-items: flex-end;
            }

            .statusIndicator.red {
                color: #da1e28;
            }
            .statusIndicator.green {
                color: #24a148;
            }

            .additional-data-wrapper {
                color: black;
                font-size: 1.875vw;
                display: flex;
                align-items: flex-end;                
                font-family: "IBM Plex Sans";
            }
        }

        .child-tiles {
            padding: 0px 32px 0px 16px;
            border-left: $cyan20 solid 3px;

            .tile-title {
                color: $blue60;
            }
        }
        .child-tiles:first-child {
            border-left: none;
            padding-left: 0px;
        }
        .child-tiles:last-child {
            padding-right: 0px;
        }
    }

    .content-switcher {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .switcher-title {
            margin-right: 16px;
        }

        .bx--content-switcher {
            width: 510px;
        }
    }
}
