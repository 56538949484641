.pm-domain {

    .domin-tile{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        float: left;
        margin-right: 15px;

        &.main {
            width: 25% !important;
            height: 150px !important;
            .prjct {
                h6 {
                    font-size: 1rem;
                    span {
                        font-size: 0.85rem;
                    }
                }
            }
        }

        &.false {
            width: 34%;
            height: 250px;
            .prjct {
                ul {
                    li {
                        padding: 5px;
                        margin-right: 5px;
                        width: 32% !important;
                      }
                }
            }
        }

        &.red {
            border-left: 4px solid #da1e28;
        }

        &.green {
            border-left: 4px solid #198038;
        }

        &.true {
            width: 36%;
            height: 300px;
        }

        .header {
            width: 100%;
            h4 {
                float: left;
                font-size: 1rem;
                text-transform: capitalize;
            }
            span {
                text-transform: capitalize;
                float: right;
            }
        }

        h5 {
            span {
                display: block;
                width: 100%;
                font-size: 0.75rem;
                font-weight: 300;
            }
        }

        .prjct {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;
            margin-top: 15px;
            h6 {
                color: #4589ff;
                span {
                    display: block;
                    width: 100%;
                    font-weight: 300;
                    font-size: 0.75rem;
                    color: #161616;
                }
            }
            ul {
                width: 100%;
                display: flex;
                height: 55px;
                flex-wrap: wrap;
                &.ul-details {
                    min-height: 120px;
                    height: 100px;
                    li {
                        &.case {
                            .icon-svg {
                                background-color: #9ef0f0;
                            }
                        }
                        &.event {
                            .icon-svg {
                                background-color: #d0e2ff;
                            }
                        }
                    }
                }
                li {
                    padding: 5px;
                    margin-right: 5px;
                    width: 38%;
                    &.case {
                        .icon-svg {
                            background-color: #fddc69;
                        }
                    }
                    &.event {
                        .icon-svg {
                            background-color: #a7F0ba;
                        }
                    }
                    .icon-svg {
                        float: left;
                        width: 25%;
                        padding: 8%;
                        text-align: center;
                        border-radius: 5px;
                        svg {
                            vertical-align: middle;
                        }
                    }
                    p {
                        width: 70%;
                        float: right;
                        font-weight: 600;
                        margin-left: 5px;
                        span {
                            width: 100%;
                            display: block;
                            font-size: 0.8rem;
                            font-weight: 300;
                        }
                    }

                    &.currency {
                        width: 20%;
                        p {
                            width: 100%;
                            text-align: center;
                            svg {
                                vertical-align: sub;
                            }
                        }
                    }

                    &.remain {
                            width: 36%;
                            p {
                                width: 100%;
                                font-size: 0.8rem;

                                svg {
                                    vertical-align: sub;
                                }
                            }
                            &.cust {
                                width: 29%;
                            }
                            &.last {
                                width: 30%;
                            }
                    }
                }
            }
        }
    }
}

.teal-heading {
    margin: 0;
}

.vs-progressBar {
    background-color: white;
    margin-top: 15px;
    padding: 5px;
    height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #a0a0a0;
    }
    .cds--progress-label{
        p {
            line-height: 1rem;
            color: #0f62fe;
        }
    }
    .cds--progress--vertical 
        {

            .cds--progress-label{
                max-inline-size: 15rem;
                width: fit-content;
            }

            .cds--progress-optional{
                inline-size: 100%;
                margin-top: 10px;
                svg {
                    vertical-align: bottom;
                    &.success {
                        fill:   #42be65
                    }
                    &.err {
                        fill: #ff8389;
                    }
                }
            }
            li {
                &:last-child {
                    svg {
                        margin-top: 30px;
                    }
                    .cds--progress-text{
                        margin-top: 30px;
                    }
                }
            }

            .cds--progress-step-button{
                fill: rgba !important;
            }
        }

    .sec-label {
        font-size: 0.8rem;
        padding-left: 10px;
        margin-bottom: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        &.active {
            background-color: #bae6ff;
        }
    }
}

.title-til{
    height: 175px;
    h5 {
        text-transform: capitalize;
    }
    ul {
        display: flex;
        margin-top: 90px;
        flex-direction: row;
        justify-content: space-between;
        li {    
            span {
                font-size: 1rem;
                font-weight: 600;
            }
        }

    }
}

.til-bx {
    width: 32%;
    float: left;
    margin-right: 20px;
    min-block-size: 11rem !important;
    max-block-size: 11rem !important;
    &:last-child {
        margin-right: 0;
    }
    &.green {
        border-left: 4px solid #198038;
    }
    &.con {
        ul {
            display: flex;
            flex-direction: row !important;
            flex-wrap: wrap;
            li {
                width: 48%;
                font-size: 0.8rem;
            }
        }
        &.green {
            border-left: 4px solid #198038;
        }
    }
    &.tech {
        ul {
            display: flex;
            flex-direction: column !important;
            height: 80%;
            overflow-y: auto;
            li {
                width: 100%;
                font-size: 0.8rem;
                margin-top: 0 !important;
                span {
                    display: inline;
                    font-size: 0.8rem;
                }
            }
        }
        &.red {
            border-left: 4px solid #da1e28;
        }
    }
    h5{
        color: #0f62fe;
    }
    h6{
        color: #000;
    }

    ul {
        display: flex;
        flex-direction: column;
        li {
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 10px;
            span {
                display: flex;
                font-size: 0.75rem;
                font-weight: 200;
            }
            .child {
                display: inline;
                margin-left: 5px;
            }
        }
    }
}

.box-view {
.appli {
    font-size: 1rem;
    margin: 5px 0 0;
    float: right;
}

.view-ml {
    padding-top: 10px;
    color: #4589ff;
    width: 50%;
    float: left;
    &:hover {
        text-decoration: underline;
    }
}
}

.anomaly-svg {
    width: 100%;
    height: 1200px;
    img {
        width: 1000px;
    }
}

.exe-dom li {
    width: 100%;
    display: flex;
    h4 {
        font-size: 1rem;
        font-weight: 600;
        width: 50%; 
        color: #4589ff;
        &:first-child {
            width: 40%;
        }
   }
    h5 {
        width: 40% !important;
        float: left;
    }
    span {
        width: 60%;
    }
} 