div#my_dataviz {
  margin-top: 10px;
  position: relative;
  background-color: white;
  svg {
    padding: 10px;
  }
  .APAC {
		animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
	}
	@keyframes blinker {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
}
#tooltip {
  td,
  th {
    padding: 1px 4px;
  }
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    background: #fff;
    position: absolute;
    /* box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5); */
    z-index: -0;
    bottom: -5px;
    left: calc(50% - 10px);
  }
  li {
    div{
        margin-bottom: 5px;
        font-size: 0.85rem;
    }
  }
}
.key0, .key2, .key4, .key6, .key8, .key10, .key12, .key14{
    float: left;
    width: 62%;
    }
