.inference-tile-container {
  overflow-x: auto;
  max-height: 80px;
  white-space: pre-line;

  .inf-title {
    font-weight: 900;
  }
  .text-description {
    font-weight: bold;
    font-size: 10px;
  }
}

.tile-header {
  font-size: 15px;
  font-weight: 900;
}

.tile-value {
  font-size: 20px;
}
