/** @format */
@import "../../../Css/variable";

.green-header {
  background-color: $susops_bg !important;

  .cds--label {
    color: #fff !important;
  }

  .cds--header__name:hover {
    color: #ffffff;
    background-color: #006434 !important;
  }

  a.cds--header__menu-item {
    background-color: $susops_bg;
  }

  a.cds--header__menu-item:hover {
    background-color: #006434 !important;
  }

  .cds--btn--icon-only:hover {
    background-color: #006434;
  }

  .cds--list-box .cds--text-input {
    background-color: $susops_bg !important;
    color: white;
  }

  .cds--list-box .cds--text-input:hover {
    background-color: #006434 !important;
  }
}

.header-profile {
  .user-name {
    color: #f3f3f3;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    padding-top: 10px;
    margin-bottom: 15px;
  }

  .role-name {
    font-size: 0.9rem;

    .role-icon {
      margin-bottom: -4px;
      margin-right: 5px;
    }
  }

  .name-icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 50%;
    background: #393939;
  }

  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  width: 16.875rem;
  height: auto;
  top: 100%;
  right: 0;
  background-color: #262626;

  padding-top: 1rem;
  padding-bottom: 0;

  li {
    padding: 0.5rem 1rem;
    display: flex;
    .cds--link {
      cursor: pointer;
    }
  }

  a.cds--link,
  a.cds--link:hover,
  button,
  button:hover {
    color: #78a9ff;
  }

  button {
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .logout-container {
    background-color: #393939;
    padding: 1rem;
    cursor: pointer;
    color: #ffffff !important;
    font-size: 0.875rem;

    svg {
      margin-left: 0.5rem;
      fill: white;
      margin-top: 4px;
    }

    &:hover {
      background-color: #424242;
    }
  }
}

.header-themes {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  width: auto;
  height: auto;
  top: 100%;
  right: 46px;
  padding: 1rem;
}

.toolset-switch {
  .cds--list-box__selection {
    display: none;
  }
}

.tenant-switch {
  max-width: 200px;

  a.cds--header__menu-item:hover {
    background-color: #161616;
  }

  .cds--list-box__selection {
    display: none;
  }

  .cds--list-box .cds--text-input {
    background-color: #161616;
    color: white;
  }

  .cds--list-box__menu-icon>svg {
    fill: white;
  }

  .cds--label {
    margin-bottom: 1px;
    color: #a8a8a8;
    padding: 0 12px;
  }

  .cds--list-box {
    height: 1.5rem;
  }
}

.about-icte {
  h4 {
    font-weight: 400;
  }

  h6 {
    font-weight: 600;
  }

  .dc--component-padding {
    width: auto;
    height: 85px;
    position: relative;

    .component--image {
      margin-top: 0.5rem;
      width: 56px;
    }

    h6.component--details {
      position: absolute;
      bottom: 0;
    }
  }
}

.menuButton.cds--header__menu-trigger>svg {
  fill: white !important;

  &:hover {
    fill: white !important;
  }
}

.menuButton.cds--header__action--active {
  background: none !important;
}

.sideNav.cds--side-nav__navigation {
  background-color: white !important;
  border-right: 0.25px solid #d7d7d7;
}

.cds--side-nav__item.cds--side-nav__item--icon a.cds--side-nav__link {
  padding-left: 4rem !important;
}

#collaborateId {
  padding-top: 5px;

  img {
    width: 30px;
    vertical-align: middle;
  }

  a {
    font-size: 0.8rem;
  }
}

.my-dashboards {
  width: 8rem;
}

.cds--header__menu-bar {
  .menuItem {
    &.false {
      cursor: not-allowed;
    }
  }

  .submenu {
    &.false {
      cursor: not-allowed;
    }
  }
}

.cds--header__name {
  padding-left: 0 !important;
}


  // .cds--popover {
  //   display: none;
  // }
  // .cds--popover-caret {
  //   display: none;
  // }

  .alert-count {
    color: white;
    position: absolute;
    background: #da1e28;
    padding: 2px 5px;
    border-radius: 19px;
    font-size: 0.7rem;
    top: 5px;
    right: 10px;
}

.header-notify { 
  li{
    cursor: pointer;
    &:hover {
      background-color: #393939;
    }
  }
  h5 {
    float: left;
    width: 80%;
  }
  span {
    float: right;
    width: 20%;
    margin-top: 2px;
  }
}

.support {
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
}

.cds--side-nav__link.activelink {
  background-color: #f6f2ff;
}