/** @format */

@use "@carbon/react";
@use '@carbon/styles/scss/grid';
@include grid.flex-grid();

$feature-flags: (
  enable-css-custom-properties: true,
);
@import '@carbon/themes/scss/themes';

@import "@carbon/charts/styles.css";

@import "_common.scss";
@import "_typography.scss";
@import "_header.scss";
@import "_button.scss";

$carbon--theme: $carbon--theme--g10;

:root {
  @include carbon--theme($carbon--theme, true);
}

.carbon-theme--white {
  @include carbon--theme($carbon--theme--white, true);
}

.carbon-theme--g10 {
  @include carbon--theme($carbon--theme--g10, true);
}

.carbon-theme--g90 {
  @include carbon--theme($carbon--theme--g90, true);
}

.carbon-theme--g100 {
  @include carbon--theme($carbon--theme--g100, true);
}

.cds--header {
  background-color: #161616;
  color: #f4f4f4;

  .cds--header__name {
    color: #f4f4f4;
  }

  .cds--header__name:hover {
    color: #ffffff;
    background-color: #393939;
  }
}

a.cds--header__menu-item {
  background-color: #161616;
  color: #f4f4f4;
}

a.cds--header__menu-item:hover {
  background-color: #393939;
  color: #ffffff;

  .cds--header__menu-arrow {
    fill: #ffffff;
  }
}

.cds--header__menu-arrow {
  color: #f4f4f4;
}

.cds--header__action:hover {
  background-color: #393939;
}

.cds--header__menu-title[aria-expanded=true]+.cds--header__menu .cds--header__menu-item {
  background-color: #393939;
}

.cds--header__menu-title[aria-expanded=true]+.cds--header__menu .cds--header__menu-item:hover {
  background-color: #4c4c4c;
  color: #ffffff;
}

.cds--header__menu-title[aria-expanded=true]+.cds--header__action:active,
a.cds--header__menu-item:active {
  background-color: #4c4c4c;
  color: #ffffff;
}

.container-multichart {
  .cds--chart-holder .cds--cc--toolbar {
    display: none;
  }
}

.cds--slider {
  min-width: 8rem !important;
}

button.cds--modal-close {
  position: absolute;
  right: 0;
  top: 0;
}

#dashboard {
  iframe {
    width: 100%;
    min-height: 250vw;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.date-picker--margin {
  .cds--date-picker__icon {
    inset-block-start: 70% !important;
  }
}

.form-lable--default {
  margin-block-end: 0.5rem;
}