/** @format */
:root {
  --svg-color: translateX(40px)
}

.skeletonPlaceholder {
  padding: 10px;

  .cds--skeleton__placeholder {
    width: 100%;
    height: 400px;
  }
}

.skeletonPlaceholder--it-health {
  padding: 10px !important;

  .cds--skeleton__placeholder {
    width: 100% !important;
    height: 18.125rem !important;
    max-width: 94rem;
  }
}

.charts-image-box {
  height: 17rem;
  width: 100%;
}

.node_orange {
  border: 2px solid orange;
}


#bp--marker {
  fill: darkslategray;
}

.node_red {
  border-left: 4px solid red;
}

.html-spinner {
  width: 64px;
  height: 64px;
  border: 2px solid orange;
  border-top: 2px solid white;
  border-radius: 50%;
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

.html-spinner-orange {
  width: 64px;
  height: 64px;
  border: 2px solid orange;
  border-top: 2px solid white;
  border-radius: 50%;
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

.html-spinner-red {
  width: 64px;
  height: 64px;
  border: 2px solid red;
  border-top: 2px solid white !important;
  border-radius: 50%;
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.node_red {
  border-left: 4px solid red;
}

@keyframes bordergrowth {
  0% {
    border: 0px solid red;
  }

  100% {
    border: 5px solid red;
  }
}

.scrollable-table {
  .cds--data-table tr {
    display: table;
    table-layout: fixed;
  }

  .cds--data-table tbody {
    display: block;
    height: 400px;
    overflow-y: auto;
  }

  .cds--data-table {
    display: flex;
    table-layout: fixed;
    flex-flow: column;
  }
}

.topology-graph {
  .cds--cc--edge__inner {
    stroke: medium-grey;
  }
}

#marker {
  fill: medium-grey;
}

.cds--breadcrumb {
  margin-bottom: 1rem;
}

.data-unavailable {
  text-align: center;
  padding-top: 100px;
}

.cds--tile.chart-container {
  display: inline-block;
  width: 100%;

  .left-sec {
    float: left;
    width: 40%;
    padding: 0;

    h5 {
      font-weight: 500;
      padding: 5px 0;
    }

    h1 {
      padding: 0 10px;

      svg {
        position: relative;
        left: -12px;
      }
    }
  }

  .show-chart {
    float: right;
    width: 60%;
  }
}

.hide-toolbar {
  .cds--chart-holder .cds--cc--toolbar {
    display: none;
  }
}

.cds--cc--highlight rect.highlight-bar {
  transform: var(--svg-color);
}

.chart-dropdown-wrapper {
  position: relative;

  .dropdown-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    right: 0.5rem;
    z-index: 1;
    width: 30%;

    .cds--dropdown {
      background-color: #f4f4f4;
      margin-right: 0.5rem;
      width: 100%;
    }
  }
}

.cds--cc--title {
  p.title {
    font-size: 1rem !important;
  }
}

.belowTit {
  position: absolute;
  bottom: 70px;
  left: 90px;
}