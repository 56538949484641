.appscan-manual-legends {
  li.cds--list__item {
    color: white;
  }

  .legend-name {
    font-size: 12px;
    font-weight: 500;
    width: 80%;
    color: #525252;
  }

  .legend-value {
    width: 15%;
    font-size: 12px;
    font-weight: 400;
    color: #525252;
  }
}

.twistlockDashboard {
  .chart-scroll {
    height: 480px;
  }
}

.top-level-issues-tile {
  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5 {
      font-weight: 400;
    }

    h3 {
      font-weight: 500;
      color: #da1e28;
    }
  }

  div:last-child {
    display: flex;
    justify-content: space-between;
  }
}

.dotted--list {
  margin-left: 1rem;
  margin-top: 1rem;

  li {
    margin-bottom: 0.5rem;
    position: relative;
  }

  li::before {
    content: "•";
    position: absolute;
    color: #000;
    display: inline-block;
    left: -1rem;
    top: -0.2rem;
    font-size: 20px;
  }
}

.action--panel {
  height: 350px;
  background-color: #000;
  color: #fff;
  padding: 1rem 0.5rem;
  overflow-y: auto;

  .dotted--list {
    li::before {
      color: #fff;
    }
  }
}

.section-divider {
  margin: 1rem 0 0 0;
}

.app--issues--tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
  }

  h5 {
    font-weight: 400;
  }

  h3 {
    font-weight: 500;
  }
}

.active--tile {
  background-color: #e0e0e0;
}

.snippets {
  .cds--snippet--multi {
    background-color: #f4f4f4;
    color: #000;
  }
}

ul.worker-details {
  display: grid;

  li {
    p {
      float: left;
      width: 25%;
    }

    span {
      margin-top: 3px;
      display: block;
    }
  }
}

.overdue-tag {
  float: right;
  margin-left: 2px;
  margin-top: 0;
}

.generate--column {
  display: flex;
  align-items: center;
}

ul.img-regis p {
  float: left;
  width: 35%;
}

.code-fix {
  .center-alignment {
    display: block;

    .cds--snippet--multi {
      background: #e0e0e0;
    }

    .lft-col {
      height: 500px;
      overflow-y: scroll;

      ul.img-regis {
        background: #f4f4f4;
        margin-bottom: 10px;
        padding: 10px 0 0 10px;
        display: inline-block;
        width: 100%;

        p {
          float: left;
          width: 40%;
          font-weight: 400;
        }

        span {
          width: 60%;
          font-weight: 500;
          display: inline-block;
        }

        Button {
          float: right;
        }
      }
    }
  }
}

.code--quality--container.cds--grid {
  padding: 0;

  ul.worker-details li {
    display: flex;
    flex-direction: row;

    p {
      width: 50%;
    }
  }
}