@import "../../../Css/_variable.scss";

.dataTable-container {

    .incident-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-family: "IBM Plex Sans light";

        .incident-id {
            text-decoration: none;
            margin-right: 5px;
            min-width: 100px;
        }

        .buttonIcon {
            .bx--btn__icon path {
                fill: $blue60;
            }
        }

        .cds--btn--ghost {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }

        .cds--popover--bottom {
            overflow: hidden;
        }
    }

    .cds--data-table td {
        background-color: #ffff !important;
    }

    .cds--search-input {
        background: white !important;
    }

    .cds--data-table-container {

        thead {
            .cds--table-sort__flex {
                background-color: white !important;
                color: #161616 !important;
                font-family: 'IBM Plex Sans' !important;
            }

            tr {
                th {
                    background-color: white !important;
                    border-top: 1px solid #C6C6C6;
                    border-bottom: 1px solid #C6C6C6;

                    .cds--table-header-label {
                        color: #161616;
                        font-family: 'IBM Plex sans';
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }

            .cds--table-sort {
                background-color: #ffff !important;
            }
        }

        tbody {
            tr {
                font-family: 'IBM Plex Sans' !important;
                font-size: 16px;
                font-weight: 500;

                td {
                    color: #161616;
                }

                td:nth-child(2) {
                    cursor: pointer;
                }

                td {
                    .cds--child-row-inner-container {
                        padding-block: 0rem !important;
                        padding-block-end: 0rem !important;
                    }
                }
            }

            tr td .incident-link {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-family: "IBM Plex Sans light";

                .incident-id {
                    margin-right: 5px;
                    min-width: 100px;
                }

                .buttonIcon {
                    .bx--btn__icon path {
                        fill: $blue60;
                    }
                }
            }

            .cds--expandable-row--hover,
            tr.cds--parent-row:first-of-type td,
            .cds--expandable-row-hover td {
                background-color: #ffff !important;
            }
        }

        .cds--table-expand {
            overflow: hidden;
        }

        .cds--table-toolbar {
            border-top: 1px solid #E0E0E0;
            border-bottom: 1px solid #E0E0E0;
            height: 52px;
        }

        .cds--toolbar-content {
            background-color: white !important;
            inline-size: 100% !important;

            .cds--btn--ghost {
                align-items: center;
                justify-content: center;
                min-width: 48px;

                .cds--btn__icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .cds--btn--ghost:hover {
                background-color: #e5e5e5;
            }

            .cds--btn--icon-only {
                svg {
                    min-width: 20px !important;
                    min-height: 20px !important;
                }
            }
        }

        .cds--toolbar-search-container-expandable {
            margin-left: 58%;
            background-color: transparent;

            .cds--search-input {
                border-block-end: none !important;
            }
        }

        .cds--toolbar-search-container-expandable:hover {
            background-color: transparent;
        }

        .cds--search .cds--toolbar-search-container-active {
            .cds--search-input:not(:placeholder-shown) {
                background: white;
            }
        }

        .cds--search {
            //margin-left: 58%;
            inline-size: 34% !important;
            border-left: 1px solid #E0E0E0;
            border-right: 1px solid #E0E0E0;
        }

        .filter-icon {
            svg {
                width: 20px;
                height: 20px;
                color: black;
            }
        }

        .cds--table-expand {
            button {
                margin-bottom: 8px;
            }
        }

        .cds--data-table-content {
            margin-top: 20px;
        }
    }

    .optional-headers {
        margin: 5px;
        height: 26px;
    }
}