.cpg {
    .first-row{
        height: 10rem !important;
        &.cpg {
            height: 8rem !important;
        }
    }
}

.Cpg {
    .dash-box {
        padding: 10px 0;
        display: flex;
        h5 {
            float: left;
            width: 20%;
            height: 118px;
            display: flex;
            align-items: center;
            color: #0f62fe;
        }
        .box-tile {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .cds--tile--clickable {
                float: left;
                width: 23%;
                margin-right: 15px;
                min-height: 140px;
                height: 140px;
                border-radius: 5px;
                box-shadow: 1px 1px #ccc;
                padding: 10px;
                &.green{
                    border-left: 3px solid #198038;
                }
                &.red{
                    border-left: 3px solid #da1e28;
                }
                .domain {
                    font-size: 0.7rem;
                }
                h5 {
                    width: auto;
                    display: block;
                    height: auto;
                    font-size: 0.9rem;
                    margin-bottom: 5px;
                    line-height: 1rem;
                    color: #161616;
                    float: left;
                }

                .tag-bx{
                    float: right;
                }

                .kpi {
                    margin-top: 40px;
                    float: right;
                    display: block;
                    clear: both;
                    span{
                        cursor: pointer;
                    }
                  }

            
            }
        }
        
    }


    .cds--tile--clickable {
        float: left;
        width: 23.8%;
        margin-right: 15px;
        min-height: 150px;
        height: 180px;
        border-radius: 5px;
        box-shadow: 1px 1px #ccc;
    }

    .cpg-tiles-workflow{
        .cds--tile--clickable{
            height: 120px;
            min-height: 120px;
            width: 19%;
            margin-right: 1.25%;
            &:nth-child(5n) {
                margin-right: 0;
            }
            h5 {
                font-size: 0.9rem;
                height: 40px;
                width: 85%;
                float: left;
                line-height: 1.1rem;
            }
            svg {
                vertical-align: sub;
                float: right;
            }
            .descrip{
                font-size: 0.8rem;
                line-height: 0.8rem;
                margin-top: 15px;
            }
            &.green {
                background-color: #24a148;
            }
            &.red {
                background-color: #fa4d56;
            }
            &.yellow {
                background-color: #b28600;
            }

            .infoTit {
                font-size: 0.75rem;
            }
        }
    }
    .cpgTopo{
        margin-top: 15px;
        .cds--cc--card-node{
            background-color: #fff;
            box-shadow: 1px 1px #ccc;
  border-radius: 5px;
            .title-sec{
                width: 75%;
                .cds--cc--card-node__title{
                    display: flex;
                }
            }
            .some-class{
                cursor: pointer;
                margin-top: 25px;
            }
            .red-icon {
                .sb-tooltip-trigger{
                    border: none;
                    background: none;
                }
            }
        }
    }

    .header-details{
        .cds--tile {
            height: 182px;
            h5 {
                span {
                    display: flex;
                    font-size: 0.8rem;
                    font-weight: initial;
                }
            }
        }
    }

    .cpg-model {
        .cds--modal-container--lg {
            inline-size: 90%;
            block-size: 96%;
            .cds--row{
                margin-inline: 0rem;
            }
        }
    }

    .nerve-tiles {
        .cds--tile--clickable {
            float: left;
            width: 23.8%;
            margin-right: 15px;
            min-height: 150px;
            height: 180px;
            border-radius: 5px;
            box-shadow: 1px 1px #ccc;
            h5 {
                color: #4589ff;
                float: left;
                width: 70%;
                font-size: 0.9rem;
            }
            .cds--tag {
                text-transform: capitalize;
                float: right;
            }
            .cds--cc--layout-row{
                display: none;
            }
            .cds--tile.chart-container{
                margin-top: 0;
            }
            .spacer{
                display: none;
            }
            ul {
                display: flex;
                width: 100%;
                li {
                    font-size: 1.8rem;
                    font-weight: 600;
                    float: left;
                    width: 42%;
                    &:first-child {
                        margin-top: 80px;
                    }
                    span {
                        font-weight: 400;
                        font-size: 1rem;
                    }
                    &.comments {
                        font-size: 1rem;
                        padding-top: 5px;
                        width: 58%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .wrkflw-box{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .box-title {
        border-bottom: 1px solid #d0e2ff;
        margin-top: 15px;
        h5 {
            width: 12%;
            background: #d0e2ff;
            padding: 5px;
            border-radius: 2px;
            text-align: center;
            font-weight: 500;
        }
    }
}

.impact{
    margin-top: 15px;
    padding: 10px;
    background-color: white;
    min-height: 365px;
    .impact-val {
        display: flex;
        flex-direction: column;
            li {
                background-color: #f4f4f4;
                padding: 5px;
                margin-bottom: 2px;
                font-size: 0.9rem;
                &.red {
                    color: #fa4d56;
                }
                span {
                    font-size: 1rem;
                    font-weight: 600;
                }
              }      
        
    }
}

.journey-cpg{
    .cds--progress-label{
        p {
            line-height: 1rem;
            color: #0f62fe;
        }
    }
    .cds--progress--vertical 
        {
            .cds--progress-optional{
                inline-size: 80%;
                margin-top: 10px;
            }
            li {
                &:last-child {
                    svg {
                        margin-top: 30px;
                    }
                    .cds--progress-text{
                        margin-top: 30px;
                    }
                }
            }

            .cds--progress-step-button{
                fill: rgba !important;
            }
        }

    .sec-label {
        font-size: 0.8rem;
        padding-left: 10px;
    }
    
}

.workflow-view.cpg {
    .box{
        margin-top: 23px;
        .tagapp{
            float: right;
            span {
                width: 100% !important;
            }
        }
        p {
            float: left;
            color: #0f62fe;
        }
    }

    .journey-cpg{
        .structred-list-table-view{
            padding: 1rem !important;
            height: 600px;
            margin-top: 25px;
        }
    }
    
    .workflow-title {
        font-size: 16px !important;
    }

    .view-ml {
        padding-top: 10px;
        color: #4589ff;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}


.bridge {
    display: flex;
    li {
        width: 52%;
        margin: 0 !important;
        text-decoration: none;
        list-style: none;
        &:first-child {
            width: 46% !important;
        }
        .cds--tag{
            width: 100%;
            margin: 0;
            text-decoration: underline;
        }
        span{
            color: #0f62fe;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
      }
  }