/** @format */

@import "_variable.scss";

html,
body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: auto;
}

#controltower {
  height: 91vh;
  width: 100%;
  margin-top: 3rem !important;
  overflow-y: auto;
  // padding: 10px 0;

  .cds--breadcrumb {
    margin-bottom: 5px;
  }
}

.float--right {
  float: right;
}

.float--left {
  float: left;
}

.clear--right {
  clear: right;
}

.clear--left {
  clear: left;
}

.clear--both {
  clear: both;
}

.disable--component {
  pointer-events: none;
  opacity: 0.5;
}

.margin--auto {
  margin-top: 150px;
}

@mixin common--margin-top {
  margin-top: 1rem
}

@mixin common--margin-top__important {
  margin-top: 1rem !important;
}

.cds--header~.cds--content {
  @include common__margin-top__important;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.relative {
  position: relative;
}

.pl-05 {
  padding-left: 0.5rem !important;
}

.pr-05 {
  padding-right: 0.5rem !important;

  .sonar {
    height: 100%;
  }

  .sonar-project {
    h4 {
      font-weight: 500;
      width: 50%;
      float: left;

      .project-name__label {
        width: 100%;
        display: block;
        font-size: 0.75rem;
      }

      .project-name__value {
        text-transform: capitalize;
        margin-right: 10px;
      }
    }
  }
}

.custom--icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0.5px solid $blue60;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  // font-weight: bold;
  color: $blue60;
  /* You can change the text color as needed */
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.bg-white {
  background-color: #ffffff;
}

//Blue Theme

.clickable--tile-wizard .cds--tile--clickable {
  height: 200px;
  margin-top: 15px;
}

.cds--content {
  padding: 2rem 0 0 0;
}

.carbon--icons-20 {
  height: 1.25rem;
}

.carbon--icons-32 {
  height: 2rem;
}

.carbon--icons-16 {
  height: 1rem;
}

span.float--right.table-icon {
  margin-right: -240px;
}

.icon--white {
  filter: invert(1);
}

.report-title {
  font-weight: bold;
}

.iframeDiv {
  width: 100%;
  height: 56rem;
}

.event-loader {
  justify-content: center;
  padding: 20%;
}

.tile-icon {
  width: 30px;
}

.padding-1 {
  padding: 1rem;
}

.container-widget {
  .report-title {
    padding-top: 1%;
  }

  // p {
  //   font-size: 0.8rem;
  // }

  .tile-icon {
    opacity: 50%;
    height: 30px;
  }

  .arrow-icon {
    bottom: 14px;
    position: absolute;
    right: 14px;
    height: 20px;
  }

  .cds--css-grid-column {
    margin-right: 0px;
  }
}

.container-multichart {
  .cds--css-grid-column {
    margin-right: 0px;
  }
}

.cds--tile {
  @include common--margin-top;
}

.report-heading__title {
  padding: "0.45rem 0 0.45rem 0";
}

.report-update__button {
  float: right;
  margin-top: 20%;
}

.report-inline--github {
  float: right;
  margin-top: 26%;
  padding-left: 10%;
}

.report-update__button--github {
  float: right;
  margin-top: 19%;
}

.dynamic-form-max-width,
.form-field-max-width {
  max-width: 450px;
}

.headings-in-modal-body {
  color: #161616;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4;
}

.error-message,
.error {
  color: #e23f48 !important;
  font-weight: 500;
  font-size: 0.8rem;
}

.success-message,
.success {
  font-weight: bold;
}

.margin-for-scroll-footer {
  margin-bottom: 25px;
}

.m-1 {
  margin: 1rem;
}

.mb-1,
.panel-value {
  margin-bottom: 1rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  @include common--margin-top;
}

.div-disabled,
.not-authorized {
  pointer-events: none;
  opacity: 0.3 !important;
}

.inlineloader {
  width: auto;
  margin: auto;
  height: 40px;
  text-align: center !important;
  display: table !important;
}

.actions-icon {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.actions-icon:not(:last-child) {
  padding-right: 0.8rem;
}

.ibm-dc-footer-toolchain {
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  clear: both;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.connection-failed {
  height: 15px;
  margin-left: 10px;
  width: auto;
  font-weight: 600;
  line-height: 2 !important;
  text-align: left;
  color: $red;
}

.connection-successful {
  height: 15px;
  width: 100%;
  font-weight: 600;
  font-size: 1rem !important;
  text-align: left;
  color: $dark_green;
}

.connection-inprogress {
  width: 100%;
  font-weight: 600;
  font-size: 1rem !important;
  line-height: 2 !important;
  text-align: left;
  color: $dark_green;
}

.bold {
  font-weight: bold;
}

.warning {
  fill: $dark_yellow;
}

.success,
.tool--box__selected {
  fill: $dark_green;
}

.failure {
  fill: $red;
}

.refresh-nopadding {
  padding: 15px;
}

.flex-display {
  display: flex;
}

.flex-1 {
  display: flex;
  gap: 1rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-end-hor,
.flex-end {
  justify-content: flex-end;
}

.flex-end-vert {
  align-items: flex-end;
}

.flex-display-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0.5px solid $grey;
}

.event-status--pending {
  fill: #ffa702;
}

.event-status--success {
  fill: green;
}

.event-status--failed {
  fill: red;
}

.vertical-flex-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date-reset-button {
  margin-top: 24px;
  margin-left: 10px;
}

.success {
  font-weight: 400;
}

.center-screen {
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.center-screen-small {
  @extend .center-screen;
  height: 40vh;
}

.center {
  @extend .center-screen;
  height: auto;
}

.divider {
  margin: 15px 1rem 0 2rem;
  border: 1px solid rgb(222, 225, 230);
}

.heading--content--seperator {
  border: 1px solid rgb(222, 225, 230);
  margin: 0.5rem 0;
}

.cds--overflow-menu-options__option {

  &:last-child,
  &:nth-child(2) {
    display: none;
  }
}

.tooltip-button {
  padding: 0;
  border: none;
  background-color: initial;
  margin-left: 3px;
}

.large-content-scroll {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.detail-box {

  h5,
  h4 {
    font-weight: bold;
  }

  .detail-left-panel {
    p {
      font-weight: normal;
      width: 10rem;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      cursor: pointer;
    }

    padding: 1rem;
    padding-left: 0;
    margin-right: 0 !important;
  }

  .detail-right-panel {
    .cds--structured-list-td {
      vertical-align: middle !important;
    }

    margin-left: 0 !important;
    padding: 1rem;
    padding-right: 0;
  }
}

.detail-box-import {
  .left-panel-import {
    p {
      font-weight: normal;
      width: 10rem;
      display: inline-block;
    }

    padding: 1rem;
    padding-left: 0;
    margin-right: 0 !important;
  }

  .right-panel-import {
    margin-left: 0 !important;
    padding: 1rem;
    padding-right: 0;
  }
}

.add-button {
  padding-right: 15px;
}

.add-button-wrapper {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: end;
}

.add-button-inline {
  height: 2.5rem;
  padding-right: 12px;
}

.svg-adj-text {
  vertical-align: super;
}

.refresh-details {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
  fill: #7f877f;
}

.cell-tool-name {
  margin-top: 5px;
  margin-left: 2px;
}

.router-Link {
  text-decoration: none !important;
}

.pipeline-details {
  @include common--margin-top;

  .inference-box {
    background-color: #ffffff;
    padding: 1rem;
  }

  .cds--structured-list-td {
    vertical-align: middle !important;
  }

  .svg-adj-text {
    vertical-align: top;
  }
}

.carbon-calc-table {
  background-color: #ffffff;
  margin-bottom: 1rem;

  input,
  .cds--list-box__field {
    background-color: #f4f4f4 !important;
  }

  .cds--dropdown {
    line-height: 40px;
  }

  svg {
    cursor: pointer;
  }

  .row-heading {
    .cds--label {
      font-size: 1rem;
      margin-bottom: 0;
    }

    .cds--structured-list-td {
      padding: 0.5rem;
    }
  }

  .row-first,
  .row-second {
    width: 100%;

    .cds--structured-list-td {
      vertical-align: bottom;
    }
  }
}

.details-list {
  .cds--structured-list-th {
    padding-bottom: 1rem !important;
  }

  .cds--structured-list-td {
    padding: 0.5rem !important;
  }

  .cds--structured-list-row:nth-child(2n) {
    background-color: #efefef;
  }

  .cds--structured-list-row--header-row {
    background-color: #c6c6c6;
  }
}

.details-history {
  @extend .details-list;

  .cds--structured-list-th,
  .cds--structured-list-td {
    padding: 0.75rem !important;
  }
}

.dynamic-table-heading {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  background: #bbbbbb;
}

.dynamic-tables {
  @extend .details-list;

  .cds--structured-list-th,
  .cds--structured-list-td {
    padding: 0.5rem !important;
  }

  .cds--structured-list-row--header-row {
    background-color: inherit;
  }
}

.inline-button {
  margin-left: 5px;
  margin-top: 3px;
}

.inline--primary-button {
  margin-top: 1.5rem;
}

.buttons-bottom-right {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
}

.error-page {
  padding: 10%;
  text-align: center;

  h1 {
    padding: 1%;
  }

  p {
    font-size: 1rem;
  }

  img {
    fill: $red;
  }
}

.vertical-bottom {
  vertical-align: bottom;
}

.inline-svg {
  cursor: pointer;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  vertical-align: middle;
}

.show-more {
  font-size: 0.7rem;
  min-height: 0;
  padding: 2px 0 0 0;
  text-decoration: underline;
  background-color: initial;
  border: 1px solid transparent;
  color: #0f62fe;
  font-weight: 400;

  &:hover {
    text-decoration: none;
  }
}

.notification {
  position: absolute;
  top: 4rem;
  right: 2rem;
  width: 25%;
}

.pointer {
  cursor: pointer;
}

.empty--message {
  font-weight: normal;
  text-align: center;
}

.inline--icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.catalouge--icon {
  cursor: pointer;
  font-size: 0.8rem;

  svg {
    fill: #4e50d0;
    vertical-align: sub;
    margin-right: 2px;
  }

  &:hover {
    border-bottom: 1px solid #2b2c2e;
  }
}

.reduced-padding-3 {
  &:nth-child(n):not(:nth-child(1)):not(:nth-child(3n + 1)) {
    padding-left: 0.5rem;
  }

  &:nth-child(n):not(:nth-child(3n)) {
    padding-right: 0.5rem;
  }
}

.chart-containet-table {
  max-height: 332px;
  overflow-y: auto;
  @include common--margin-top;

}

.style--placeholder {
  input::placeholder {
    color: #161616;
  }
}

.add--button {
  padding-right: 10px;
  gap: 10px;
  white-space: normal;

  p {
    font-size: 14px;
  }
}

.nested--tile {
  height: calc(100% - 1rem);
  width: 100%;
  background-color: #fff;
  @include common--margin-top;

  .cds--tile {
    margin-top: 0;
  }
}

.common-margin__bottom {
  margin-bottom: $spacing-03;
}

.error-refresh-page {
  text-align: center;

  p {
    font-weight: normal;
  }

  .button__refresh {
    padding-right: 12px;
    gap: 25px;
  }
}

.override-form-flex {
  .cds--form-item {
    align-items: flex-end;
  }
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 1rem;
}