.pnc-value-stream {
    ul.portfolio-fss {
        background: none;
        border: none;
        font-size: 0.75rem;

        li {
            svg {
                vertical-align: middle;
                margin-left: 2px;
            }

            &.active {
                background: none;
                color: #0f62fe;
            }
        }
    }

    .cds--toggle {
        margin-top: 10px;
    }

    .pnc-tiles {
        padding-right: 0 !important;

        .cds--tile--clickable {
            margin-top: 5px;
            min-height: 90px;
            border-radius: 5px;
            box-shadow: 1px 1px #ccc;

            &.active {
                background-color: #e5e5e5;
            }

            .flow-title {
                font-weight: 600;
                font-size: 1rem;
            }

            .sec-col {
                float: right;

                h5 {
                    font-size: 0.8rem;
                    text-align: center;
                    width: 100%;
                    display: block;
                }
            }

            .orange {
                color: #ba4e00;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1.5rem;
                text-align: center;
                width: 100%;
                display: block;
            }

            .red {
                color: #da1e28;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1.5rem;
                text-align: center;
                width: 100%;
                display: block;
            }

            .green {
                color: #198038;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1.5rem;
                text-align: center;
                width: 100%;
                display: block;
            }
        }
    }

    #row1 {
        .f-col {
            width: 100%;
            margin-top: 10px;

            .row-title {
                float: left;
                width: 84%;

                svg {
                    margin-top: 5px;
                }
            }

            .refresh {
                font-size: .8rem;
                color: #001d6c;
                font-weight: 600;
                cursor: pointer;
                margin-left: 10px;
                float: right;
                margin-top: 15px;

                svg {
                    vertical-align: bottom;

                    &.rotateIcons {
                        animation: rotation;
                        animation-duration: 1.5s;
                        animation-timing-function: linear;
                        animation-delay: 0s;
                        animation-direction: reverse;
                        animation-iteration-count: infinite;
                        animation-fill-mode: none;
                        animation-play-state: running;
                    }
                }
            }

            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(359deg);
                }
            }
        }

        .cds--inline-loading__animation {
            width: 100%;
        }

        .title {
            float: left;
            width: 70%;
        }

        // .sec-col {
        //     float: right;
        // }
    }

    #row2 {
        svg {
            margin-top: 5px;
        }

        border-top: 1px solid #ccc;
        margin-top: 15px;
        padding-top: 10px;

        .title {
            float: left;
            width: 70%;
        }

        .sec-col {
            float: right;
        }
    }

    #row3 {
        svg {
            margin-top: 5px;
        }

        border-top: 1px solid #ccc;
        margin-top: 15px;
        padding-top: 10px;

        ul.serv-info {
            display: flex;

            li {
                width: 50%;

                span {
                    font-size: 1.2rem;
                    font-weight: 600;
                }

                .red {
                    color: #da1e28;
                    text-align: left;
                }
            }
        }
    }

    #row4 {
        // border-top: 1px solid #ccc;
        // margin-top: 15px;
        // padding-top: 10px;
    }

    .refresh-icon{
        padding: 10px;
    }

    .row-title {
        display: flex;
        width: 100%;
        // margin-left: 20px;
        font-weight: 600;
        font-size: 1rem;
    }

    .last-col {
        h4 {
            font-weight: 500;
        }

        .cds--tile {
            width: 20px;
            float: left;
            margin-right: 15px;
            margin-top: 5px;
        }

        .active {
            background-color: #e5e5e5;
        }
    }

    td:last-child {
        width: 7%;
    }

    ul.action-icons li {
        float: left;
        padding: 3px;
        margin-right: 0;
        svg {
            cursor: pointer;
        }
    }

    .center-alignment {
        display: block;

        .pop-content-lft {
            float: left;
            width: 30%;
            background-color: #f4f4f4;
            padding: 10px;
            margin-right: 15px;

            ul {
                li {
                    h5 {
                        font-weight: 600;
                    }
                }
            }
        }

        .pop-content-rgt {
            float: left;
            width: 30%;
            background-color: #f4f4f4;
            padding: 10px;

            ul {
                li {
                    h5 {
                        font-weight: 600;
                    }

                    svg {
                        vertical-align: middle;
                    }

                    span {
                        margin-left: 20px;
                    }
                }
            }
        }

        .tabs {
            margin-top: 15px;
            display: inline-block;
            width: 100%;

            .cds--tab-content {
                background: #f4f4f4;
                padding: 10px !important;

                ul {
                    li {
                        list-style: disc;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .getDetails {
        background: #edf5ff;
        overflow-y: auto;

        ul {
            padding: 5px;
            border-bottom: 1px solid #ccc;

            li {
                margin-bottom: 5px;

                h5 {
                    font-size: .8rem;
                    font-weight: 800;
                    line-height: .85rem;
                }

                span {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .hd-sec {
        cursor: pointer;
    }

    .hd-sec.active {
        color: #4589ff;
    }

    .error {
        margin-left: 5px !important;
        color: #a2191f;
        font-weight: 600;
    }

    .cds--data-table {
        tr {
            position: relative;
        }
    }

    .toggle-tip {
        position: absolute;
        top: 12px;
        .cds--toggletip-label {
            margin-right: 5px;
            vertical-align: top;
        }
    }

    .owner-grp {
        li {
            display: flex;

            h5 {
                float: left;
                font-size: .75rem;
                width: 40%;
                margin-right: 8px;
                font-weight: 600;
            }

            span {
                float: right;
                width: 80%;
                font-size: 0.8rem;
            }
        }
    }

    .err-log {
        background: #fff;
        margin-top: 24px;
        padding: 10px;

        &:first-child {
            margin-top: 30px;
        }

        li {
            display: flex;

            h5 {
                float: left;
                font-size: .8rem;
                width: 20%;
                font-weight: 600;
            }

            span {
                float: right;
                width: 80%;
                font-size: 0.8rem;
            }
        }
    }

    .serciceTag {
        clear: both;
        width: 100%;
        display: block;
        height: 30px;

        .serv-span {
            float: left;
            padding-top: 8px;
            font-weight: 600;
            margin-right: 2px;
            font-size: 0.9rem;
        }
    }

    .box-tile {
        box-shadow: 1px 1px #e0e0e0;
        float: left;
        height: 104px !important;
        margin-right: 14px;
        margin-top: 5px;
        text-align: center;
        width: 45%;
        min-height: 1rem;

        h6 {
            font-weight: 600;
        }

        &.active {
            background-color: #e5e5e5;
        }
    }

    .serv-box {
        width: 90%;
    }

    .paddingRgt {
        padding-right: 0 !important;
    }

    .paddingLft {
        padding-left: 0 !important;
    }

    .link-external {
        width: 100%;
        display: flex;
        justify-content: end;
        position: absolute;
        right: 0;

        a {
            padding: 2px 10px;
            background: white;
            border: 1px solid #0f62fe;
        }
    }

    ul.service-Tile {
        background: #fff;
        display: flex;
        /* height: 250px; */
        padding: 10px;
        flex-flow: row wrap;
        height: 404px;
        overflow-y: auto;

        li {
            border: 1px solid #ccc;
            float: left;
            font-size: .7rem;
            height: 100px;
            margin: 5px;
            padding: 5px;
            width: 19%;
            cursor: pointer;

            &.red {
                background: #fa4d56;
            }

            &.green {
                background: #24a148;
            }

            h6 {
                font-size: 0.8rem;
                line-height: 0.8rem;
                height: 55px;
                color: white;
                font-weight: 600;
                text-align: center;
            }

            .errors {
                font-size: 1rem;
                color: white;
                font-weight: 600;
                float: left;

                h6 {
                    font-size: 0.7rem;
                    height: 15px;
                }
            }

            .availablity {
                font-size: 1rem;
                color: white;
                font-weight: 600;
                float: right;

                h6 {
                    font-size: 0.7rem;
                    height: 15px;
                }
            }
        }
    }

    .table-tiles {
        float: left;
        margin: 10px;
        text-align: center;
        padding: 10px;

        span {
            font-size: 1.5rem;
            font-weight: 600;
        }

        h5 {
            font-size: 0.75rem;
            font-weight: 500;
        }
    }

    .pnc-topo {
        background: white;
        padding: 20px;
        width: 100%;

        // h5 {
        //     font-weight: 600;
        // }

        .card-node {
            padding: 10px 2px;
            background-color: #f0f0f0;
        }

        .elk-title-tooltip {
            font-size: .75rem;
        }

        .draw-edge {
            animation: link-anim-bt linear 20s infinite reverse;
        }

        @keyframes link-anim-bt {
            0% {
                stroke-dashoffset: 0;
            }

            100% {
                stroke-dashoffset: 400;
            }
        }
    }

    .verticaltabs {
        margin-top: 5px;
        position: relative;

        .left-switch {
            // background: #e0e0e0;
            border: none;
            border-radius: 2px;
            flex-direction: column;
            float: left;
            width: 13rem !important;

            .ticket {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 600;
                height: 53px;
                padding: 18px;
                text-align: center;
                background-color: #000000;
            }

            .lft-title {
                padding: 10px;
                background-color: #c6c6c6;

                h5 {
                    font-weight: 600;
                }
            }

            .cds--tile--clickable {
                margin-top: 0;
                padding: 0;
                min-height: 2rem;
                margin-bottom: 0px;
                border-bottom: 1px solid #c6c6c6;
                background-color: #e0e0e0;

                h6 {
                    padding: 12px;
                    padding-left: 30px;
                }

                &.active {
                    h6 {
                        background: #f4f4f4;
                        border-left: 4px solid #8a3ffc;
                        font-weight: 600;
                    }
                }
            }

            .cds--content-switcher-btn {
                width: 50%;
                border-radius: 0;
            }

            li {
                padding: 10px;
                background: #f3f3f3;
                //border-bottom: 1px solid #151515;
                border-left: 1px solid #151515;
                border-right: 1px solid #151515;
            }
        }

        .cds--tile.tile-switch {
            // background-color: #f4f4f4;
            // box-shadow: 1px 1px #e0e0e0;
            // height: 500px;
            // margin-left: 209px;
            margin-top: 0;
            overflow-y: auto;
            width: 82%;
            padding: 0;
            height: 600px;
            padding-left: 10px;
            position: relative;
            background-color: #f4f4f4;

            .feedback {
                position: sticky;
                top: 0;
                width: 20%;
                float: right;

                button {
                    font-size: 0.7rem;
                    padding: 8px;
                    width: 100%;
                    min-height: 1rem;
                    margin-bottom: 10px;
                }
            }
        }

        .cds--tile.tile-switch.changeReq {
            margin-left: 0px;
            width: 100%;
        }

        .rgt-switch {
            background: #fff;
            border-bottom: 0;
            border-radius: 2px;
            display: flex;
            width: 100%;

            .cds--tile--clickable {
                float: left;
                margin-top: 0;
                width: 22%;
                min-height: 2rem;
                padding: 0;
                background: #e0e0e0;

                border-bottom: 0;

                &.active {
                    border-top: 3px solid #0f62fe;

                    h6 {
                        background: #f4f4f4;
                        padding: 15px;
                        font-weight: 600;
                    }
                }

                h6 {
                    width: 100%;
                    float: left;
                    padding: 15px;
                    font-weight: 500;
                    border-left: 1px solid #c6c6c6;

                    svg {
                        vertical-align: text-top;
                    }
                }

                // &::after {
                //     content: "|";
                //     width: 2px;
                //     height: 2rem;
                //     display: inline-block;
                //     padding-top: 10px;
                //     vertical-align: sub;
                //     opacity: 0.3;
                // }

                &:last-child {
                    &::after {
                        content: "";
                    }
                }
            }

            .disabled {
                opacity: 0.5;
            }

            li {
                width: 22%;
                float: left;
                padding: 10px;

                h6 {
                    width: 96%;
                    float: left;
                }

                &::after {
                    content: "|";
                }

                &:last-child {
                    &::after {
                        content: "";
                    }
                }
            }
        }

        .monitor {
            background-color: white;
            padding: 10px;
            margin-bottom: 10px;
        }
    }

    ul.riskAss {
        margin-bottom: 10px;
        background: #f4f4f4;
        padding: 10px;

        li {
            font-size: 0.8rem;
            padding-top: 2px;

            h6 {
                font-weight: 600;
            }
        }
    }

    .tag-req {
        width: 25%;
    }

    .summary {
        position: relative;

        h5 {
            font-weight: 600;
        }

        ul {
            list-style-type: disc;
            padding-left: 20px;

            li {
                font-size: 0.85rem;
                margin-bottom: 5px;
            }
        }

        p {
            line-height: .85rem;
            font-size: 0.85rem;
        }
    }
}

.feedback-popup {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: end;
}