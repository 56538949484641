ul.action-icons li {
    float: left;
    margin-right: 5px;
    cursor: pointer;
}


.summary {
    .cds--modal-container {
        width: 90%;
        padding: 0 10px;
    }

    .verticaltabs {
        margin-top: 5px;
        position: relative;
        display: flex;

        .err-log{
            background: #edf5ff;
            margin-bottom: 10px;
            &:first-child{
                margin-top: 15px;
            }
        }

        .left-switch {
            border: none;
            border-radius: 2px;
            flex-direction: column;
            float: left;
            width: 15rem !important;
            margin-top: 15px;
            background-color: #fff;

            .ticket {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 600;
                height: 53px;
                padding: 18px;
                text-align: center;
                background-color: #000000;
            }

            .lft-title {
                padding: 10px;
                background-color: #c6c6c6;

                h5 {
                    font-weight: 600;
                }
            }

            .cds--tile--clickable {
                margin-top: 0;
                padding: 0;
                min-height: 2rem;
                margin-bottom: 0px;
                border-bottom: 1px solid #c6c6c6;
                background-color: #e0e0e0;

                h6 {
                    padding: 12px;
                    padding-left: 30px;
                }

                &.active {
                    h6 {
                        background: #f4f4f4;
                        border-left: 4px solid #8a3ffc;
                        font-weight: 600;
                    }
                }
            }

            .cds--content-switcher-btn {
                width: 50%;
                border-radius: 0;
            }

            li {
                padding: 0;
                background: #fff;
            }
        }

        .cds--tile.tile-switch {
            // background-color: #f4f4f4;
            // box-shadow: 1px 1px #e0e0e0;
            // height: 500px;
            // margin-left: 209px;
            margin-top: 0;
            overflow-y: auto;
            width: 82%;
            padding: 0;
            height: 540px;
            padding-left: 10px;
            position: relative;
            background-color: #f4f4f4;

            .feedback {
                position: sticky;
                top: 0;
                width: 20%;
                float: right;

                button {
                    font-size: 0.7rem;
                    padding: 8px;
                    width: 100%;
                    min-height: 1rem;
                    margin-bottom: 10px;
                }
            }
        }

        .cds--tile.tile-switch.changeReq {
            margin-left: 0px;
            width: 100%;
        }

        // .rgt-switch {
        //     background: #fff;
        //     border-bottom: 0;
        //     border-radius: 2px;
        //     display: flex;
        //     width: 100%;

        //     .cds--tile--clickable {
        //         float: left;
        //         margin-top: 0;
        //         width: 22%;
        //         min-height: 2rem;
        //         padding: 0;
        //         background: #e0e0e0;

        //         border-bottom: 0;

        //         &.active {
        //             border-top: 3px solid #0f62fe;

        //             h6 {
        //                 background: #f4f4f4;
        //                 padding: 15px;
        //                 font-weight: 600;
        //             }
        //         }

        //         h6 {
        //             width: 100%;
        //             float: left;
        //             padding: 15px;
        //             font-weight: 500;
        //             border-left: 1px solid #c6c6c6;

        //             svg {
        //                 vertical-align: text-top;
        //             }
        //         }

        //         // &::after {
        //         //     content: "|";
        //         //     width: 2px;
        //         //     height: 2rem;
        //         //     display: inline-block;
        //         //     padding-top: 10px;
        //         //     vertical-align: sub;
        //         //     opacity: 0.3;
        //         // }

        //         &:last-child {
        //             &::after {
        //                 content: "";
        //             }
        //         }
        //     }

        //     .disabled {
        //         opacity: 0.5;
        //     }

        //     li {
        //         width: 22%;
        //         float: left;
        //         padding: 10px;

        //         h6 {
        //             width: 96%;
        //             float: left;
        //         }

        //         &::after {
        //             content: "|";
        //         }

        //         &:last-child {
        //             &::after {
        //                 content: "";
        //             }
        //         }
        //     }
        // }

        .monitor {
            background-color: white;
            padding: 10px;
            margin-bottom: 10px;
        }
    }
}