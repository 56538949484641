/** @format */

$blue10: #edf5ff;
$blue20: #d0e2ff;
$blue30: #a6c8ff;
$blue40: #78a9ff;
$blue50: #4589ff;
$blue60: #0f62fe;
$blue70: #0043ce;
$blue80: #002d9c;
$blue90: #001d6c;
$blue100: #001141;

$heading-black: #0d0d0d;
$text-black: #a1a1a1;
$grey: #f4f4f4;
$medium-grey: #c6c6c6;
$light-grey: #e0e0e0;
$tile-hover: #e5e5e5;
$dark-grey: #666666;
$white: #fff;

// new landing page colors
$yellow: #fddc69;
$navy_blue: #061727;
$light_blue: #0062ff;
$light_grey: #f3f3f3; // background yaml file
$carbon_light_grey: #f4f4f4; // white theme background color
$light_black: #b1b1b1; // box shadow
$light_sky: #edf6fd;

$dark_blue: #0b54ac; // button and link visited
$very_light_blue: #5eacf7; // link
$medium_blue: #c8e4fc; // on hover table sort
$very_light_sky: #e7f3fd; // table header
$red: #d61212; // error or warning
$light_pink: #ffe5e5; // text box error
$white: #ffffff; // back ground in blue theme
$black: #171717; // normal text color
$dark_green: #00ab0a; // success
$dark_yellow: #ffa702; // tool provision pending
$light_green: #ade1bb;
$button_disable: #dfe9e9; // disabled button
$disabled_tile_background: #e5e5e5;

$spacing-00: 0rem; //0px
$spacing-01: 0.125rem; //2px
$spacing-02: 0.25rem; //4px
$spacing-03: 0.5rem; //8px
$spacing-04: 0.75rem; //12px
$spacing-05: 1rem; //16px
$spacing-06: 1.5rem; //24px
$spacing-07: 2rem; //32px
$spacing-08: 2.5rem; //40px
$spacing-09: 3rem; //48px
$spacing-10: 4rem; //64px
$spacing-11: 5rem; //80px
$spacing-12: 6rem; //96px
$spacing-13: 10rem; //160px
$spacing-14: 12rem; //192px
$spacing-15: 24rem; //384px

//DO colors
$cyan20: #bae6ff;
$blue60: #0f62fe;
$gray100: #161616;
$blue60: #0f62fe;

//Theme colors

//1. Gray 100
// Primary text;
// Body copy;
// Headers;
// Hover text color for $text-secondary
// $text-primary: #00539a; //#161616;
// $text-primary-grey: #161616;
// 2. Gray 100
// High contrast elements;
// AA element contrast
// $layer-selected-inverse: #1192e8; //#161616

// 3. White
// Default page background;
// UI Shell base color
// $background: #f4f4f4; // #ffffff

// 4. White
// Text on interactive colors;
// Text on button colors
$text-on-color: #ffffff;

// 5. Grey 50
// Hover color for background
// $background-hover: #bae6ff;

//------------------WHITE THEME: BACKGROUND----------------

// Default page background;
// UI Shell base color
// White
$background: #ffffff;

// Hover color for $background;
// Hover color for transparent backgrounds
// Gray 50, 12%
$background-hover: #8d8d8d;

// Active color for $background
// Gray 50, 50%
$background-active: #8d8d8d;

// Selected color for $background
// Gray 50, 20%
$background-selected: #8d8d8d;

// Hover color for $background-selected
// Gray 50, 32%
$background-selected-hover: #8d8d8d;

// High contrast backgrounds;
// High contrast elements Gray 80
$background-inverse: #393939;

// Hover color for $background-inverse
// Gray 80 hover
$background-inverse-hover: #4c4c4c;

// Feature background color
// Blue 60
$background-brand: #0f62fe;

//----------------WHITE THEME: TEXT----------------

// Primary text;
// Body copy;
// Headers;
// Hover text color for $text-secondary Gray 100
$text-primary: #161616;

// Secondary text;
// Input labels Gray 70
$text-secondary: #525252;

// Placeholder text Gray 40
$text-placeholder: #a8a8a8;

// Text on interactive colors;
// Text on button colors White
$text-on-color: #ffffff;

// Disabled color for $text-on-color Gray 50
$text-on-color-disabled: #8d8d8d;

// Tertiary text;
// Help text Gray 60
$text-helper: #6f6f6f;

// Error message text Red 60
$text-error: #da1e28;

// Inverse text color White
$text-inverse: #ffffff;

// Disabled text color Gray 100 – 25%
$text-disabled: #161616;

//----------------WHITE THEME: BUTTON----------------

$button-primary: #0f62fe;

// -----------------------------------------FROM DC--------------------------------------------

//Things that vary

// new color variables
$dark_blue: #0b54ac; // button and link visited
$light_blue: #0f62fe; // button on hover
$very_light_blue: #5eacf7; // link
$medium_blue: #c8e4fc; // on hover table sort
$very_light_sky: #e7f3fd; // table header
$red: #d61212; // error or warning
$light_pink: #ffe5e5; // text box error
$white: #ffffff; // back ground in blue theme
$black: #333333; // normal text color
$dark_green: #00ab0a; // success
$dark_yellow: #ffa702; // tool provision pending
$light_green: #ade1bb;
$button_disable: #dfe9e9; // disabled button
$grey: #e5e5e5; // border
$text_disable_grey: #a8a5a5; // text disabled
$light_grey: #f3f3f3; // background yaml file
$carbon_light_grey: #f4f4f4; // white theme background color
$light_black: #b1b1b1; // box shadow
$light_sky: #edf6fd;
/* fonts */
$default_font_color: $black;
$base_font_size: 0.875rem;

// susops
$susops_primary: #106020;
$susops_bg: #00793f;
$susops_borders: #198038;
$susops_bg_text: #f1eaea;