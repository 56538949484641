@import "./_dashboard.scss";
$w: 320px;

.alertAnalytics-container {
    background-color: #f0f0f0;
    font-family: IBM Plex Sans;
    margin-top: 40px;
    width: 100%;

    .dashboard-grouped-bar-graph {
        margin: 0 auto;
    }

    .infra-alert-metrics-table {
        .cds--data-table tr {
            border-top: 1px solid #E0E0E0;
        }
    }

    .dashboard-detail-container .nested-table .expanded-table-title {
        padding: 15px 0px 15px 15px !important;
        border-bottom: 2px solid !important;
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.16px;
    }

    .cds--toolbar-search-container-expandable .cds--search .cds--search-close::before {
        height: 0px !important;
    }

    .cds--toolbar-action:hover {
        background: none;
    }

    .search-button:hover {
        background: #e5e5e5;
    }

    .cds--toolbar-search-container-expandable .cds--search .cds--search-magnifier:hover {
        background: none;
    }

    // .cds--toolbar-content .cds--search .cds--search-input,
    // .cds--toolbar-search-container-active .cds--search-input:not(:placeholder-shown) {
    //     background-color: #ffffff !important;
    // }

    .content-switcher {
        width: fit-content;
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: 20px;

        .cds--content-switcher-btn {
            height: 32px;
            width: fit-content;
        }
    }

    .gauge-report {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    .search-button {
        height: 32px;
        margin-top: 8px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    }

    .gauge-container {
        margin-bottom: 40px;
        @include flex-box(row, center, center);

        >div {
            width: 222px;
            margin: 0px 30px;
            text-align: center;
        }
    }

    // css adjustment for table
    .alert-metrics {
        .cds--expandable-row:not(:nth-child(1)) {
            td {
                padding-right: 0px !important;
            }
        }

        .cds--data-table {
            width: 100%;

            tr.cds--parent-row:nth-of-type(n) td:nth-child(2) {
                width: $w !important;
                min-width: $w !important;
                table-layout: unset;
            }

            tbody {
                tr {
                    .cds--table-expand {
                        overflow: hidden !important;
                    }

                    .cds--child-row-inner-container {
                        .cds--table-expand {
                            overflow: hidden !important;
                        }
                    }
                }
            }
        }

        .nested-table {
            .expanded-table-title {
                padding: 15px 0px 0px 30px;
            }

            .cds--data-table {
                th {
                    &:not(:first-child) {
                        min-width: 120px !important;
                    }
                }

                .cds--child-row-inner-container {
                    margin-left: 20px !important;

                    .table-container {
                        margin-left: 0px !important;

                        .cds--data-table {
                            thead {
                                display: none;
                            }

                            tr {
                                &:first-child {
                                    td {
                                        border-top: unset;
                                    }
                                }

                                td {
                                    &:nth-child(1) {
                                        min-width: $w !important;
                                        width: $w !important;
                                        max-width: $w !important;
                                        overflow-wrap: break-word;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cds--tabs--scrollable__nav-link,
    .cds--tabs--scrollable__nav-item--selected {
        min-width: 220px;
        width: fit-content;
    }

}