/** @format */

@import "_variable.scss";

.cds--btn {

	&.cds--btn--ghost {
		padding: 0 !important;
		font-size: $base_font_size;
		min-height: 0rem;
		text-decoration: underline;
		&:hover {
			background-color: transparent;
			text-decoration: none;
		}
		&:focus {
			border-color: transparent;
			box-shadow: 0 0;
		}
		&.cds--btn--disabled {
			border-color: transparent !important;
			text-decoration: none;
		}
	}
	svg{
		margin-right: 5px;
	}
}

.button--margin-right{
  margin-right: 20px;
}

.button--margin-left{
  margin-left: 20px;
}