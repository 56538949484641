@import "../../../Css/_variable.scss";

@mixin flex-box($direction: row, $align-items: center, $justify-content: space-between) {
    display: flex;
    flex-direction: $direction;
    align-items: $align-items;
    justify-content: $justify-content;
    width: 100%;
}

.dashboard-detail-container {
    background-color: transparent;
    margin-top: -25px;

    .remove-underline {
        text-decoration: none !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    .graph-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 300;

        .table-toolbar {
            display: flex;
            flex: 1;
            align-items: center;
            padding-left: 50px;

            .cds--table-toolbar {
                background: none;
            }
        }

        .download-btn {
            height: 32px;
            width: 40px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 100%;
                max-width: 100%;
                min-height: 100%;
                padding: 0px !important;

                .cds--btn__icon {
                    width: 20px;
                    margin-left: 0px;
                }
            }
        }
    }

    .bold-border-table {
        .cds--data-table {
            .cds--toolbar-content {
                background-color: transparent !important;
            }

            .cds--table-sort__flex,
            th,
            .cds--table-sort {
                background-color: white !important;
            }

            .cds--table-sort {
                background-color: white !important;
            }

            th {
                button {
                    background-color: $white !important;
                }

                border-bottom: 1px solid $medium-grey;
            }

            tr {
                &:last-child {
                    td {
                        border-bottom: unset;
                    }
                }
            }
        }
    }

    .dashboard-roleContainer {
        background-color: white;
        height: 167px;

        .dashboard-summary {
            display: flex;
            flex-direction: row;
            padding-top: 20px;
            padding-left: 20px;
        }
    }

    .dashboard-charts-container {
        margin-top: 30px;

        .grid-color {
            background-color: white;
            padding: 20px;
        }

        .cds--chart-holder {
            margin-bottom: 6px;
        }

        .chart-holder.cds--chart-holder.fullscreen {
            background-color: $white;
        }

        >section {
            margin-bottom: 30px;
            border-bottom: unset !important;


            .trend-graph-table {
                background-color: $white;
                padding: 20px;
            }

        }

        .report-title {
            padding: 16px 36px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: white;
        }

        .report-container {
            padding: 16px;

            .report-header {
                padding: 16px 0px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .report-icon {
                    padding: 16px 0px;
                    background-color: #f3f3f3;

                    svg {
                        margin: 0px 16px;
                    }
                }
            }
        }

        .donut-chart-container {
            display: flex;
            flex-direction: row;
        }

        .tile-view {
            display: flex;
            justify-content: space-between;

            .report-container {
                width: 50%;
                padding: 0px 16px;

                &:last-child {
                    padding: 25px;
                    background-color: white;
                }

                &:first-child {
                    padding: 25px;
                    background-color: white;
                    margin-right: 20px;
                }

                .loading-container {
                    align-items: center;
                    background: transparent;
                    box-shadow: none;
                    display: flex;
                    margin: 50px;
                    width: fit-content;

                    .cds--inline-loading {
                        justify-content: center;
                    }
                }
            }
        }

        .cds--content-switcher {
            border: solid 1px $black;
            border-radius: 5px;
            height: 34px !important;
            margin-top: 7px;
        }

        .cds--content-switcher-btn {
            background-color: $white;
        }

        .cds--content-switcher-btn.cds--content-switcher--selected {
            background-color: $black;
        }

        .message {
            background-color: $white;
            padding: 10px;
        }

        .no-padding {
            .trend-graph {
                padding: unset !important;
            }
        }

    }

    .border-bottom {
        border-bottom: 1px solid #979797;
    }

    .cds--data-table {
        th {
            border-bottom: 1px solid $medium-grey;
            border-top: 1px solid $medium-grey;
            background-color: $white !important;
            font-size: 16px;
            font-weight: 500;
            color: black;
        }

        tbody tr td {
            background-color: $white !important;
            font-size: 16px;
            font-weight: 500;
            color: black;
        }

    }

    .cds--toolbar-content {
        background-color: transparent !important;
    }

    tr.cds--parent-row.cds--expandable-row+tr[data-child-row] td {
        border-bottom: 1px solid $light-grey;
        padding-left: 16px;
        transition: padding-bottom 0.11s cubic-bezier(0.2, 0, 0.38, 0.9),
            transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9), background-color 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
    }

    .cds--btn--lg:not(.cds--btn--icon-only) {
        padding-right: 0rem;
    }

    .table-container {
        .pagination-container {
            display: flex;
        }

        .fastforward {
            border-top: 1px solid $light-grey;

            svg {
                height: 10px;
                width: 10px;
            }
        }

        .cds--pagination {
            flex-flow: wrap;
            background-color: transparent !important;
            border-bottom: solid 1px $light-grey;

            .cds--pagination__left {
                width: 325px;
                background-color: transparent;
            }

            .cds--pagination__right {
                background-color: transparent;
            }

            .cds--pagination__right .cds--pagination__text {
                margin-right: 0px !important;
                margin-left: 0.0005rem;
            }

            .cds--pagination__control-buttons {
                width: 97px;
            }
        }
    }

    .dark-table {
        .cds--data-table {

            th,
            tbody tr td {
                background-color: #f4f4f4 !important;
            }
        }

        .cds--pagination {
            background-color: #f4f4f4 !important;
        }
    }

    .white-theme-table {
        .cds--data-table {
            th {
                border-top: 1px solid black;
                border-bottom: 1px solid black;
            }
        }

        .cds--table-sort__flex {
            background-color: white;
        }

        .cds--pagination {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
        }
    }

    .nested-table {
        .expanded-table-title {
            padding: 15px 0px 0px 30px;
        }

        .cds--data-table {
            th {
                &:not(:first-child) {
                    min-width: 130px;
                }
            }

            .cds--child-row-inner-container {
                .table-container {
                    margin-left: 20px;

                    .cds--data-table {
                        thead {
                            display: none;
                        }

                        tr {
                            &:first-child {
                                td {
                                    border-top: unset;
                                }
                            }

                            td {
                                min-width: 130px;
                            }
                        }
                    }
                }
            }
        }
    }

    .top5-alert-kpi {
        .cds--data-table tbody tr td:last-child .percentage {
            align-items: center;
            display: flex;
            height: 48px;
            justify-content: center;
            max-width: 85px;
        }

        .tile-view {
            display: block;
        }

        .graph-title {
            .progress-close-container {
                display: flex;
                align-items: center;
                column-gap: 16px;
                flex-wrap: wrap;
                justify-content: flex-end;
                row-gap: 8px;

                .progress-bar-container {
                    width: 120px;
                    height: 10px;
                    background: $white;
                    margin-bottom: 16px;

                    .progress-bar-indicator {
                        height: 100%;
                        background-image: linear-gradient(to right, #0e6027, #fddc69, #da1e28);

                        #indicator {
                            width: 80%;
                        }
                    }

                    .title,
                    .range {
                        color: #6f6f6f;
                        font-size: 8px;
                    }

                    .range {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .react-card-flip {
        .react-card-back {
            .download-close-container {
                display: flex;

                .flip-button {
                    height: 32px;
                    width: 40px;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
                    text-align: center;
                    margin-left: 10px;
                    font-size: 20px;
                    font-weight: 500;
                    cursor: pointer;

                    &:hover {
                        background-color: #e5e5e5;
                    }
                }
            }
        }
    }

    .trend-graph {
        .cds--dropdown__wrapper {
            .cds--dropdown {
                height: 2.25rem !important;

                .cds--list-box__menu-icon {
                    svg {
                        margin-bottom: 20px;
                        height: 1rem !important;
                        width: 1rem !important;
                    }
                }
            }
        }
    }
}