.tile- {
    color: #4589ff;
    font-weight: 600;
}
.instrument {
    .report-title{
        text-transform: capitalize;
    }

    p.desc {
        font-size: 0.85rem;
        line-height: 1.1rem;
        margin: 5px 0;
        height: 100px;
        span {
            font-weight: 600;
            margin-right: 5px;
            width: 100%;
            display: flex;
        }
    }

    .rgt-aw {
        width: 100%;
        display: flex;
        flex-flow: row-reverse;
        color: #1192e8;
        &:hover {
            text-decoration: underline;
        }
      }

    .ml-modal {
        min-height: 120px;
        height: 180px;
        position: relative;
        .expect {
            position: absolute;
            bottom: 10px;
            font-size: 0.85rem;
            left: 15px;
            svg {
                vertical-align: sub;
            }
        }
    }

    .insights-hb {
        height: auto;
        padding: 0 !important;
    }

    .cds--accordion__item {
        .cds--accordion__content {
            display: none;
        }
    }

    .model-head {
        // position: absolute;
        // bottom: 40px;
    }

    .cds--accordion__item--active {
        .cds--accordion__content {
            display: block;
            padding-inline-end: 0;
            padding-top: 10px;
            .cds--tile{
                min-height: 80px;
                margin-top: 10px;
                margin-bottom: 10px;
                box-shadow: 1px 1px #ccc;
                border-radius: 5px;
                .report-title {
                    font-size: 0.9rem;
                    height: auto;
                    min-height: 70px;
                    display: flex;
                    .left-tit{
                        float: left;
                        width: 100%;
                        font-size: 0.7rem;
                        font-weight: 500;
                    }
                    .rgt-icon{
                        float: right;
                        width: 10%;
                        svg {
                            fill: #161616;
                        }
                    }
                    svg {
                        fill: #4589ff;
                        vertical-align: sub;
                        margin-right: 5px;
                        margin-top: 2px;
                        float: left;
                    }
                    span {
                        width: 100%;
                        font-size: 1.1rem;
                        display: flex;
                        font-weight: 500;
                        color: #8b40fd;
                        line-height: 1.4rem;
                        color: #8b40fd;
                    }
                }
                // .arrow-icon {
                //     fill: #4589ff;
                //     bottom: 14px;
                //     float: right;
                //     position: absolute;
                //     right: 14px;
                //     width: 5%;
                // }
            }

        }
    }

    .cds--modal-container--lg{
        height: 680px;
        inline-size: 80%;
    }

    .modal-head{
        h4 {
            color: #8b40fd;
            float: left;
        }
        img {
            width: 35px;
            float: left;
        }
    }

    .expected {
        font-size: 0.8rem;
        display: flex;
        flex-wrap: wrap;
        word-wrap: anywhere;
        font-weight: bold;
        margin-bottom: 15px;
        span {
            font-size: 1rem;
            font-weight: 600;
            display: flex;
            width: 100%;
        }
        p {
            font-size: 0.85rem;
        }
        .ex-out{
            span {
                font-size: 0.7rem;
                font-weight: 500;
            }
        }
      }

    .ml-modal{
        .mldesc{
            margin-bottom: 15px;
            span {
                font-size: 1rem;
                font-weight: 600;
            }
            p {
                font-size: 0.85rem;
            }
        }

        .mod {
            width: 24%;
            margin-right: 10px;
          }

        .models {
            display: flex;
            flex-direction: row ;
            flex-wrap: wrap;
            span {
                display: flex;
                width: 100%;
                font-weight: 600;
                font-size: 1rem;
                &.nm {
                    font-size: 0.75rem;
                    font-weight: 400;
                }
            }
            p {
                font-size: 0.85rem;
                font-weight: bold;
            }
            .hype {
                margin-top: 5px;
                p, span {
                    font-size: 0.8rem;
                    font-weight: 400;
                    float: left;
                    display: contents;
                }
                .cds--tag {
                    span {
                        font-size: 0.75rem;
                    }
                }
            }
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 15px;
            li {
                width: 32%;
                margin-bottom: 15px;
                font-weight: 600;
                font-size: 1rem;
                margin-right: 10px;
                span {
                    width: 100%;
                    display: flex;
                    font-size: 0.75rem;
                    font-weight: 500;
                }
                svg {
                    vertical-align: sub;
                }
            }
        }
    }
}

.create--button {
    padding: calc(0.575rem - 3px) 15px !important;
    gap: 20px;
    white-space: normal;
    align-items: center;
}

.tool--configuration{
    .cds--modal-header__heading{
        text-transform: capitalize;
    }

    .test--connection--button:hover,
    .test--connection--button:active{
        background-color: white;
        color: #0050e6;
    }
}