body {
  font-family: IBM Plex Sans !important;
  letter-spacing: 0.1599999964237213px !important;
}

.noc-ques{
  ul{
    padding: 10px;
    background: #e0e0e0;
    li {
      font-size: 0.85rem;
      padding: 5px;
      cursor: pointer;
      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
}

.telco-accordion {
  padding-top: 20px;

  .cds--accordion__content {
    padding-right: 0;
  }
}

.accordian_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.rcaContainer {
  position: absolute;
  display: contents;
  width: 100vw;
}

.breadcrumbs {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 1.5%;
  padding-left: 16px;
}

.sidebar_container {
  background: #E8E8E8;
  overflow-y: scroll;
  height: 80vh;

  .selected{
    border:2px solid #0f62fe;
  }
}

.sidebar_container_closed {
  background: none;
}

.navbar {
  width: inherit;
  margin-bottom: 20px;
  margin-top: 20px;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
  align-content: stretch;
  align-content: space-between;
  padding-right: 0px;


}

.navbar div {
  width: 50%;
  float: left;
}

.navbar div:nth-child(2) img {
  float: right;
}


.sidenav-closed div:nth-child(2) img {
  float: none;
}

.navbar img {
  cursor: pointer;
}

.nav_item {
  // height: 50px;

  .icon {
    width: 50%;
  }
}

.nav_item li {
  width: 80%;
  float: left;
}

.nav_item span {
  background: #E8E8E8;
}

.nav_item span {
  background: #E8E8E8;
  cursor: pointer;
  width: 20%;
  float: right;
  display: none;
  padding-top: 2px;
}

.nav_item span p {
  float: right;
}

.nav_item:hover span {
  display: block;
  /* Show the content on hover */
}

.sidenav-closed div {
  padding-bottom: 10px;
  width: 100%;
  text-align: center !important;
}

.chat-icons span {
  padding: 10px;
  cursor: pointer;
}

.rca-details {
  display: flex;
  background-color: white;
  padding: 5px;

  h5 {
    width: 40%;
    font-weight: 800;
  }

  h6 {
    width: 60%;
    font-weight: 400;
  }
}

.chat-container {

  float: right;
  display: block;
  width: 100%;
  height: 81vh;
  position: relative;

  .hyperlink {
    color: #0f62fe;
    text-decoration: underline;
    cursor: pointer;
  }

  .chat-wdw {
    height: 80vh !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: 0px !important;
    box-shadow: 1px 1px 5px 2px #ccc;

    .chat-title-download {
      background-color: #E0E0E0;
      padding: 10px !important;
      display: flex;

      p {
        width: 97%;
        font-weight: none !important;
      }

      .download-btn {
        padding: 5px 5px 0 8px;
        min-height: 1.8rem;
      }
    }

    .chart-history {
      overflow-y: auto;
      // display: flex;
      flex-direction: column;
      justify-content: flex-end;
      /* Ensures content stays at the bottom */

      height: 90% !important;
      width: 100% !important;
      padding: 10px;
      background-color: #fff;
      overflow-y: auto;

      .table-show-more {
        color: #0f62fe;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;
      }

      .table-show-more:hover {
        text-decoration: underline;
      }

      .dynamic-response {
        width: 100%;

        .ai-image {
          width: auto;
          float: left;
          text-align: center;
        }

        .ai-image img {
          height: 56px;
          width: 56px;
        }

        .ai-response {
          width: 90%;
          padding: 10px;
          float: left;

          .more-text p {
            overflow-wrap: break-word;
            word-break: break-all;
            word-break: normal;
            overflow-wrap: break-word;
            white-space: normal;
          }

          .show-more {
            float: right;
            cursor: pointer;
            color: #0f62fe;
          }
        }
      }


      .chat-ques-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
        padding-bottom: 30px;
        padding-right: 20px;
      }

      .chat-ques {
        background: #F4F4F4;
        border-radius: 20px;
        padding: 15px;
        border: none;
        align-self: flex-end;
      }

      .chat-icons {
        padding: 0px 20px 20px 0px;

      }

      .chat-icons span:hover {
        background-color: #f4f4f4;
        border-radius: 10px;
      }

      .help-txt {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .cds--form-item {
        display: grid !important;
        align-items: end !important;
        margin-bottom: 1%;
      }

      ;

      .chat-dashboard {
        overflow-y: auto;
        display: inline-flex;
        margin-right: 10px;
        padding-left: 20px;
        margin-inline: 0rem !important;
        width: 100%;

        .pdf-reader {
          .page {
            width: 150px;
          }
        }

        h3 {
          padding: 20px;
          font-size: 1rem;
          display: inline-flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .analyzed-data-details {
          background-color: white;
          width: 100%;

          p {
            padding-left: 30px;
            font-size: 15px;
          }

          .analyzed-data {
            display: flex;
            gap: 5px;

            h5 {
              font-weight: 600;
              padding-left: 35px;
            }

            h6 {
              font-weight: 400;
              padding-top: 5px;
              width: 80%;
            }
          }
        }

        .count-details-main {
          width: 100%;
          background-color: white;
          padding-top: 20px;
          height: 130px;

          .count-details {
            display: flex;
            padding-left: 20px;
            gap: 1rem;

            h5 {
              font-weight: 400;
            }

            h6 {
              font-weight: 800;
              padding-top: 4px;
            }
          }
        }

        .analyze-data-main {
          width: 100%;
          background-color: white;
          padding-top: 20px;

          .analyze-details {
            display: flex;
            padding-left: 20px;

            h5 {
              width: 40%;
              font-weight: 800;
            }

            h6 {
              width: 60%;
              font-weight: 800;
            }
          }
        }
      }
    }

    .chart-dashboard {
      display: flex;
      padding: 15px !important;
      background: #fff;
      overflow-y: auto;

      .help-txt {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .chat-dashboard {
        height: 90vh;
        overflow-y: auto;
        display: inline-flex;
        margin-right: 10px;
        padding-left: 5px;

        .pdf-reader {
          .page {
            width: 150px;
          }
        }


        h3 {
          padding: 20px;
          font-size: 1rem;
          display: inline-flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .analyzed-data-details {
          background-color: white;
          width: 100%;

          p {
            padding-left: 30px;
            font-size: 15px;
          }

          .analyzed-data {
            display: flex;
            gap: 5px;

            h5 {
              font-weight: 600;
              padding-left: 35px;
            }

            h6 {
              font-weight: 400;
              padding-top: 5px;
              width: 80%;
            }
          }
        }

        .count-details-main {
          width: 100%;
          background-color: white;
          padding-top: 20px;
          height: 130px;

          .count-details {
            display: flex;
            padding-left: 20px;
            gap: 1rem;

            h5 {
              font-weight: 400;
            }

            h6 {
              font-weight: 800;
              padding-top: 4px;
            }
          }
        }

        .analyze-data-main {
          width: 100%;
          background-color: white;
          padding-top: 20px;

          .analyze-details {
            display: flex;
            padding-left: 20px;

            h5 {
              width: 40%;
              font-weight: 800;
            }

            h6 {
              width: 60%;
              font-weight: 800;
            }
          }
        }
      }
    }

    .msg-input {
      padding: 30px 20px 30px 20px;
      background-color: #fff;
    }

    .msg-input .inputSpan {
      float: left;
      width: 100%;
      .cds--form-item{
        width: 100%;
      }
    }

    .msg-input .sendIcon {
      float: right;
      position: absolute;
      padding: 5px;
      right: 35px;
      width: 2%;
      cursor: pointer;
      bottom: 39px;
    }

    .msg-input .sendIcon svg.disabled {
      fill: rebeccapurple;
    }

    .msg-input .sendIcon svg.stop {
      fill: #525252;
    }

    .msg-input #msg-input {
      border: none;
      background-color: #F4F4F4;
      border-radius: 20px;
    }

  }

  .chat-icn {
    right: 10px;
    position: absolute;
    bottom: 50px;
    cursor: pointer;
  }
}

.filled-icon {
  color: #0F62FE;
}

// Overriding carbon and other css

.rcaContainer {
  .cds--data-table {
    width: auto;
  }

  // .cds--data-table--lg

  .cds--text-input__field-outer-wrapper {
    flex-direction: column-reverse !important;
  }

  .cds--text-input__field-wrapper {
    background: white;
  }

  .cds--accordion__title {
    font-weight: bold !important;
  }

  .cds--row {
    margin-inline: 0rem !important;
  }

  .cds--menu-item {
    padding-right: 0px;
  }

  .cds--menu-item__label {
    color: #000;
  }

  .cds--accordion__item {
    border-block-start: none !important
  }

  .cds--popover-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}